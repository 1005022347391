import fetcher from './fetcher';
import { API_ENDPOINTS, WITH_GLOBAL_FILTERS } from '../config';

export default async function getAppConfig(urlParams = {}) {
	const newUrlParams = { ...(urlParams || {}) }

	if (WITH_GLOBAL_FILTERS) {
		if (!newUrlParams['client']) {
			const companies = await fetcher({
				path: '/companies/global-filter/',
				urlParams: { ...newUrlParams, nitems: 1000 }
			});
			if (Array.isArray(companies))
				newUrlParams['client'] = companies[0]?.pk
		}
		if (!newUrlParams['contract']) {
			const contracts = await fetcher({
				path: '/contracts/global-filter/',
				urlParams: { ...newUrlParams, nitems: 1000 }
			});
			if (Array.isArray(contracts))
				newUrlParams['contract'] = contracts[0]?.pk
		}
	}
	const config = await fetcher({
		path: API_ENDPOINTS.config,
		urlParams
	});
	return config;
}
