import authenticatedFetcher from './fetcher';

export default class ckUploader {
	constructor(loader) {
		this.loader = loader;
	}
	// Starts the upload process.
	upload() {
		return this.loader.file.then(
			(file) =>
				new Promise(async (resolve, reject) => {
					const formData = new FormData();
					formData.append('file', file);
					return authenticatedFetcher({
						method: 'POST',
						path: '/file-upload/',
						file: true,
						body: formData
					})
						.then((data) => {
							resolve({
								default: `${process.env.REACT_APP_MEDIA_BASE_URL}${data?.path}`
							});
						})
						.catch((error) => {
							console.log('Error saving file', error);
							reject(error);
						});
					//SOURCE: HOW CONNECT CKEDITOR TO FIREBASE
				})
		);
	}
}
