import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { FormGroup, Input, Label } from 'reactstrap';
import useSelectFormListener from '../../../hooks/useSelectFormListener';
import { getInputStatus, getItems } from '../../../services/commonServices';
import authenticatedFetcher from '../../../services/fetcher';
import InputDescription from '../../InputUtils/InputDescription';

const TypeMultiCheckbox = (props) => {
	const {
		label,
		value,
		name,
		options: inputConfig = { key: 'value', print: 'label' },
		onChange,
		required,
		errors,
		isEditing = true,
		globalParams = {},
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		alwaysEditableFields,
		editable,
		relatedData,
		withoutFetch,
		onlyInput,
		inputClass,
		listenDefault,
		defaultValue,
		divClassName
	} = props;
	const [currentOptions, setCurrentOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState({});
	const [isMounted, setIsMounted] = useState(false);
	const [customParams, isHearing] = useSelectFormListener(props);
	const hasChange = useRef(false);

	useEffect(() => {
		if (!isMounted && !isHearing && !withoutFetch) handleSearch(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.values(customParams).length && !withoutFetch) handleSearch(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customParams]);

	useEffect(() => {
		if (hasChange.current && typeof onChange === 'function')
			onChange({
				target: { name, value: Object.values(selectedOptions).map((item) => item[inputConfig.key]) }
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	useEffect(() => {
		if (listenDefault && defaultValue) handleSearch(defaultValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	const handleSearch = async (newValues) => {
		const defaultValues = newValues && Array.isArray(newValues) ? newValues : [];
		const params = inputConfig.params ? inputConfig.params : {};
		const paramsFixed = inputConfig['params-fixed'] ? inputConfig['params-fixed'] : {};
		const data = await authenticatedFetcher({
			path: `${inputConfig.endpoint}`,
			urlParams: { ...params, ...paramsFixed, ...globalParams, ...customParams }
		});
		if (data.error || !data) {
			setIsMounted(true);
			return setCurrentOptions([]);
		}
		const items = getItems(data);
		const newData = items
			? items.reduce(
					(acc, item, i) => {
						if (defaultValues.some((selectedItem) => selectedItem === item.pk)) {
							return {
								...acc,
								selected: { ...acc.selected, [i]: item },
								options: [...acc.options, item]
							};
						} else {
							return {
								...acc,
								options: [...acc.options, item]
							};
						}
					},
					{ options: [], selected: {} }
			  )
			: null;
		setIsMounted(true);
		if (newData) {
			setCurrentOptions(newData.options);
			setSelectedOptions(newData.selected);
		}
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable && isEditing;

	const getStatus = () => {
		const isValid = Array.isArray(value) && value.length;
		return getInputStatus(required, isValid, errors);
	};

	const handleChange = (idx) => () => {
		if (!hasChange.current) hasChange.current = true;
		setSelectedOptions((prev) => {
			if (prev[idx]) {
				const newState = { ...prev };
				delete newState[idx];
				return newState;
			} else return { ...prev, [idx]: currentOptions[idx] };
		});
	};
	console.log('divclassname = ' +divClassName);
	const renderInput = () => (
		<div className={'d-block ' + getStatus() || ''}>
			{currentOptions &&
				currentOptions.map((option, i) => {
					const optionId = `${name}-options-${i}`;
					return (
						<FormGroup key={i} className={'mb-2 ' + (divClassName !== '' ? divClassName : 'd-block')} check>
							<Label className={inputClass} check>
								<Input
									id={optionId}
									name={optionId}
									type="checkbox"
									checked={!!selectedOptions[i]}
									onChange={handleChange(i)}
									disabled={!isEditable || !isEditing}
									className="mt-0"
								/>
								{option[inputConfig.print]}
							</Label>
							<InputDescription description={relatedData ? relatedData.description : ''} />
						</FormGroup>
					);
				})}
		</div>
	);

	if (onlyInput) return renderInput();

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>{renderInput()}</Col>
		</FormGroup>
	);
};

export default TypeMultiCheckbox;
