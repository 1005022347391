import React, {Fragment} from 'react';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SidebarHeader, SidebarMenu, SidebarFooter } from '..';

const Sidebar = (props) => {
	const { sidebarStyle, sidebarShadow, sidebarFooter, openMenu, toggleMenu, toggleMobileMenu } = props;

	return (
		<>
			<div
				className={clsx('app-sidebar', sidebarStyle, {
					'app-sidebar--shadow': sidebarShadow,
					'app-sidebar-desktop-closed': openMenu
				})} style={{backgroundColor: '#fff'}}>
				<SidebarHeader toggleMenu={toggleMenu} openMenu={openMenu} />

				<div className="app-sidebar-desktop-toggle d-none d-md-block">
					<Button onClick={toggleMenu} size="sm" color="primary" className="p-0 btn-icon d-40">
						<FontAwesomeIcon icon={['fas', 'bars']} />
					</Button>
				</div>
				<div className="app-sidebar--content">
					<SidebarMenu toggleMobileMenu={toggleMobileMenu} />
				</div>
				{sidebarFooter && <SidebarFooter />}

			</div>
			<div
				onClick={toggleMenu}
				className={clsx('app-sidebar-overlay', {
					'is-active': openMenu
				})}
			/>
		</>
	);
};

export default Sidebar;
