const DEV_BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
const PRO_BASE_URL = process.env.REACT_APP_PRO_BASE_URL;
const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
const REACT_APP_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const REACT_APP_LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
const REACT_APP_RELATEDCARDS_MODE = process.env.REACT_APP_RELATEDCARDS_MODE;
const TABLE_REDIRECT_DOUBLECLICK = process.env.REACT_APP_TABLE_REDIRECT_DOUBLECLICK === 'true';
const MAX_SLUG_SIZE = process.env.REACT_APP_MAX_SLUG_SIZE;
const TO_UPPER_CASE = process.env.REACT_APP_TO_UPPER_CASE;
const WITH_LANGUAGES = parseInt(process.env.REACT_APP_WITH_LANGUAGES);
const LANG = process.env.REACT_APP_LANG;
const ENDPOINT_NUM_ALARMS = process.env.REACT_APP_ENDPOINT_NUM_ALARMS;
const PAGE_ALARMS = process.env.REACT_APP_PAGE_ALARMS;
const REFRESH_ALARMS = !!process.env.REACT_APP_REFRESH_ALARMS;
const WITH_GLOBAL_FILTERS = !!parseInt(process.env.REACT_APP_WITH_GLOBAL_FILTERS);
export const FAST_SEARCHERS_WITH_ENTER = parseInt(process.env.REACT_APP_FAST_SEARCHERS_WITH_ENTER);

const BASE_URL = process.env.NODE_ENV === 'production' ? PRO_BASE_URL : DEV_BASE_URL;

const API_ENDPOINTS = {
	config: '/config/'
};

const CUSTOM_PAGES_INFO = {
	assets: {
		paramKey: 'asset',
		endpoint: '/assets/'
	},
	asset_telegestio: {
		paramKey: 'asset',
		endpoint: '/tele-assets/'
	},
	installation: {
		paramKey: 'installation',
		endpoint: '/installations/'
	},
	installation_telegestio: {
		paramKey: 'installation',
		endpoint: '/tele-installations/'
	},
	element: {
		paramKey: 'element',
		endpoint: '/elements/'
	},
	element_telegestio: {
		paramKey: 'element',
		endpoint: '/tele-elements/'
	},
	ticketing: {
		paramKey: 'ot',
		endpoint: '/ticketing-ots/',
		endpoint_data: '/ticketing/ots/'
	},
	contracts: {
		paramKey: 'contracts',
		endpoint: '/contracts/'
	},
	empresa: {
		paramKey: 'empresa',
		endpoint: '/companies/'
	},
	recertification: {
		paramKey: 'batch',
		endpoint_data: '/exams/recertification-groups/'
	}
};

const APP_TITLE = process.env.REACT_APP_PROJECT_NAME || 'ADMIN';
const SUBDIRECTORY_URL = process.env.REACT_APP_SUBDIRECTORY_URL || '';

export {
	API_ENDPOINTS,
	REACT_APP_LOGIN_URL,
	REACT_APP_LOGOUT_URL,
	REACT_APP_RELATEDCARDS_MODE,
	DEV_BASE_URL,
	PRO_BASE_URL,
	BASE_URL,
	APP_TITLE,
	CUSTOM_PAGES_INFO,
	MEDIA_BASE_URL,
	TABLE_REDIRECT_DOUBLECLICK,
	MAX_SLUG_SIZE,
	TO_UPPER_CASE,
	WITH_LANGUAGES,
	LANG,
	ENDPOINT_NUM_ALARMS,
	PAGE_ALARMS,
	REFRESH_ALARMS,
	WITH_GLOBAL_FILTERS,
	SUBDIRECTORY_URL
};
