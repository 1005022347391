import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Button } from 'reactstrap';
import authenticatedFetcher from '../../services/fetcher';
import LoadingSpinner from '../LoadingSpinner';
import { Form } from 'react-bootstrap';
import { toastError, toastSuccess } from '../../utils/ToastConfig';

export default function CustomModalChangeInsuranceGroup(props) {
	const { id } = useParams();
	const { toggle, open } = props;
	const [formData, setFormData] = useState({});
	const [insuranceGroups, setInsuranceGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingButton, setLoadingButton] = useState(false);

	const resetModal = () => {
		setFormData({});
        toggle()
	};

	const handleChange = ({ target: { value, name } }) => {
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoadingButton(true);
		await authenticatedFetcher({
			path: '/exams/recertification-groups/change-insurance-group/',
			method: 'POST',
			body: { ...formData, person_pk: id }
		}).then((res) => {
			setLoadingButton(false);
			if (res.__status || res.error) {
				if (res.error_data && Array.isArray(res.error_data.non_field_errors))
					res.error_data.non_field_errors.map((item) => toastError(item));
				else toastError(res.error);
			} else {
				if ('response' in res) {
					toastSuccess("Grupo cambiado con éxito", { autoClose: true });
					resetModal();
					document.location.reload();
				}
			}
		});
	};

	useEffect(() => {
		authenticatedFetcher({ path: `/exams/recertification-groups/insurance-groups-actual-and-last/` }).then((res) => {
			setInsuranceGroups(res);
			setLoading(false);
		});
	}, []);

	return (
		<Modal size="sm" toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				Cambio de grupo de recertificación Seguros
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div>
							{loading ? (
								<div className="d-flex justify-content-center">
									<LoadingSpinner show={loading} />
								</div>
							) : (
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-md-12">
											<Form.Group>
												<Form.Label>Cambiar grupo de recertificación a:</Form.Label>
												<Form.Control
													as="select"
													name="recertification_group"
													value={formData.recertification_group}
													onChange={handleChange}>
													<option value=""></option>
													{insuranceGroups.map((item) => (
														<option key={item.pk} value={item.pk}>
															{item.name}
														</option>
													))}
												</Form.Control>
											</Form.Group>
											<div className="w-100 mb-3 d-flex justify-content-end">
												<Button
													type="submit"
													variant="secondary d-block mr-2"
													className="submitButton"
													disabled={loadingButton || !formData.recertification_group}>
													Cambiar de grupo
													<LoadingSpinner show={loadingButton} />
												</Button>
											</div>
										</div>
									</div>
								</form>
							)}
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}

CustomModalChangeInsuranceGroup.defaultProps = {
	toggle: () => {},
};
