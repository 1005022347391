const gt = (param1, param2) => param1 !== undefined && param1 > param2;
const gte = (param1, param2) => param1 !== undefined && param1 >= param2;
const lt = (param1, param2) => param1 === undefined || param1 < param2;
const lte = (param1, param2) => param1 === undefined || param1 <= param2;
const eq = (param1, param2) => param1 === param2;
const neq = (param1, param2) => param1 !== param2;
const lengt = (param1 = '', param2) => (typeof param1 === 'string' ? param1.length > param2 : false);
const lengte = (param1 = '', param2) => (typeof param1 === 'string' ? param1.length >= param2 : false);
const lenlt = (param1 = '', param2) => (typeof param1 === 'string' ? param1.length < param2 : false);
const lenlte = (param1 = '', param2) => (typeof param1 === 'string' ? param1.length <= param2 : false);
const notin = (param1, param2 = []) => Array.isArray(param2) && !param2.some((item) => item === param1);
const inval = (param1, param2 = []) => Array.isArray(param2) && param2.some((item) => item === param1);
const vclengt = (param1, param2 = []) => (Array.isArray(param1) && param1.length > param2);
const vclenlt = (param1, param2 = []) => (Array.isArray(param1) && param1.length < param2);
const vclen = (param1, param2 = []) => !Array.isArray(param1) || (Array.isArray(param1) && param1.length === param2);

const customOperators = {
	gt,
	gte,
	lt,
	lte,
	eq,
	neq,
	lengt,
	lengte,
	lenlt,
	lenlte,
	notin,
	in: inval,
	vclengt,
	vclenlt,
	vclen
};

export default customOperators;
