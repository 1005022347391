import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PrintInputError from '../../InputUtils/PrintInputError';
import { Col, FormGroup, Label } from 'reactstrap';
import { getInputStatus, getItems } from '../../../services/commonServices';
import authenticatedFetcher from '../../../services/fetcher';
import useSelectFormListener from '../../../hooks/useSelectFormListener';
import InputDescription from '../../InputUtils/InputDescription';
import ToMapBtn from '../../MapComponents/ToMapBtn';

const TypeSelect = (props) => {
	const {
		label,
		value,
		name,
		options: inputConfig,
		onChange,
		isEditing = true,
		editable,
		optionalContent,
		defaultValue,
		required,
		errors,
		isContractPage,
		className,
		globalParams = {},
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		alwaysEditableFields,
		relatedData,
		toggleMap = () => () => { },
		returnAll,
		divClassName
	} = props;

	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState();
	const [selectedIdx, setSelectedIdx] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [customParams, isHearing] = useSelectFormListener(props);

	useEffect(() => {
		if (!value && defaultValue) onChange({ target: { name, value: defaultValue } });
		if (value) setSelectedOption({ [inputConfig.key]: value, [inputConfig.print]: '' });
		if (!isHearing) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.values(customParams).length) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customParams]);

	const handleSearch = async () => {
		const params = inputConfig.params ? inputConfig.params : {};
		const paramsFixed = inputConfig['params-fixed'] ? inputConfig['params-fixed'] : {};
		const noBlankOption = inputConfig['noBlankOption'] ? inputConfig['noBlankOption'] : false;
		setIsLoading(true)
		const data = await authenticatedFetcher({
			path: `${inputConfig.endpoint}`,
			urlParams: { ...globalParams, ...params, ...paramsFixed, ...customParams, from_select: 1 }
		});
		setIsLoading(false)
		if (data.error || !data)
			return setOptions([
				{
					[inputConfig.key]: null,
					[inputConfig.print]: ''
				}
			]);
		const items = getItems(data);
		let newData = [];
		if (noBlankOption) {
			newData = [...items];
		} else {
			newData = [
				{
					[inputConfig.key]: null,
					[inputConfig.print]: ''
				},
				...items
			];
		}
		let valueIdx = newData.findIndex((item) => item[inputConfig.key] === value)
		valueIdx = valueIdx >= 0 ? valueIdx : 0
		const newSelectedItem = newData[valueIdx]
		setSelectedIdx(() => valueIdx);
		onChange({ target: { name, value: returnAll ? newSelectedItem : newSelectedItem[inputConfig.key] } });
		setOptions(newData);
	};

	const handleChange = ({ target: { value: newValueIndex } }) => {
		const selectedItem = options[newValueIndex];
		setSelectedOption(selectedItem);
		setSelectedIdx(newValueIndex);
		onChange({ target: { name, value: returnAll ? selectedItem : selectedItem[inputConfig.key] } });
	};

	const withOptionContent = optionalContent || props.map_config ? true : false;
	const inputC = withOptionContent
		? {
			xs: inputColumn.xs > 4 ? inputColumn.xs - 4 : undefined,
			md: inputColumn.md > 4 ? inputColumn.md - 4 : undefined
		}
		: inputColumn;

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	return (
		<FormGroup tag="fieldset" row className={divClassName}>
			<Col {...labelColumn}>
				<Label className={cx(isContractPage && 'form-2-last-name')} for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputC}>
				<div className={`input-select-async-wrapper ${getInputStatus(required, value, errors)}`}>
					<select
						className={cx('form-control', !isEditing && 'remove-arrow', className)}
						name={name}
						onChange={handleChange}
						style={{ padding: 0 }}
						disabled={!isEditing || !isEditable || isLoading}
						value={selectedIdx || ''}>
						{options &&
							options.map((option, i) => {
								return (
									<option key={`select-${name}-option${i}`} value={i}>
										{option[inputConfig.print]}
									</option>
								);
							})}
					</select>
				</div>
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
			{withOptionContent ? (
				<Col xs="4" md="4">
					{optionalContent ||
						(selectedOption && <ToMapBtn onClick={toggleMap(selectedOption, props.map_config)} />)}
				</Col>
			) : null}
		</FormGroup>
	);
};

TypeSelect.propTypes = {};

export default TypeSelect;
