import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchRelatedCards, getTabsFromFormData } from '../../utils';

import DynamicFormGroups from './RelatedCards/DynamicFormGroups';
import ButtonsBar from '../ButtonsBar';
import { searchFields } from '../../services/commonServices';
import DynamicLayoutByType from './DynamicLayoutByType';
import parse from 'html-react-parser';
import cx from 'classnames';
import AppContext from '../../context';
import { REACT_APP_RELATEDCARDS_MODE } from '../../config';
import FormContext from '../../context/FormContext';

const DynamicFormTabLayout = (props) => {
	const {
		onSubmit,
		config,
		data,
		withoutRelateds,
		tabProcessingOptions = {},
		withoutButtonsBar,
		isCreating,
		filterMode,
		resetAll
	} = props;
	const [loading, setLoading] = useState(true);
	const [tabs, setTabs] = useState(null);
	const [alwaysEditableFields, setAlwaysEditableFields] = useState([]);
	const [groupedData, setGroupedData] = useState(null);
	const [headerData, setHeaderData] = useState(null);
	const { config: { routes } = {} } = useContext(AppContext);
	const { saveRefreshRelated } = useContext(FormContext);

	async function fetchForm() {
		const processedTabs = getTabsFromFormData(config, data, { filterMode, ...tabProcessingOptions });
		setTabs(processedTabs);
		setLoading(false);
	}

	useEffect(() => {
		fetchForm();
		buildHeaderData();
		if (!withoutRelateds && config.relations) setTimeout(() => getRelatedCards(), 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const buildHeaderData = () => {
		const header = searchFields([['header__', '']], data);
		if (Object.keys(header).length) setHeaderData(header);
	};

	const getRelatedCards = async () => {
		const groupsData = await fetchRelatedCards(config, data, routes);
		setGroupedData(groupsData);
	};

	const handleUnblockField = (fieldsToChange = []) => {
		if (!Array.isArray(fieldsToChange)) return null;
		setAlwaysEditableFields(fieldsToChange);
	};

	const renderRelatedCards = () => {
		if (!Array.isArray(groupedData)) {
			return null;
		}
		return (
			<DynamicFormGroups
				withFormContext
				className="mt-5"
				groupedData={groupedData}
				showMode={REACT_APP_RELATEDCARDS_MODE}
				isCreating={isCreating}
				config={config}
			/>
		);
	};

	if (loading) return null;

	// if (location && location.pathname && location.pathname.includes('/elements/')) {
	// 	const [, , id] = location.pathname.split('/');
	// 	return <EditElement id={id} config={config} onSubmit={onSubmit} />;
	// }

	const renderHeader = () => {
		return headerData?.text ? (
			<div className={cx('mb-3 p-3 message', headerData.class)}>{parse(headerData.text || '')}</div>
		) : null;
	};

	return (
		<Fragment>
			{!withoutButtonsBar ? (
				<ButtonsBar
					config={config}
					formData={data}
					fetchData={resetAll}
					handleUnblockField={handleUnblockField}
					saveRefreshRelated={saveRefreshRelated}
				/>
			) : null}
			{renderHeader()}
			<DynamicLayoutByType
				defaultTab={tabs.defaultTab}
				tabs={tabs.tabs}
				tabsContent={tabs.tabsContent}
				config={config}
				onSubmit={onSubmit}
				formTitle={data?.form_title}
				isCreating={isCreating}
				filterMode={filterMode}
				alwaysEditableFields={alwaysEditableFields}
				showSaveBtn={!data?.block_form}
			/>
			{!withoutRelateds ? renderRelatedCards() : null}
		</Fragment>
	);
};

DynamicFormTabLayout.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	config: PropTypes.object.isRequired,
	data: PropTypes.object
};

DynamicFormTabLayout.defaultProps = {
	data: {}
};

export default DynamicFormTabLayout;
