import React from 'react';
import { Col, Row, FormGroup, Label } from 'reactstrap';
import DynamicFormInput from '../DynamicFormInput';

export default function TypeFilterInitEnd(props) {
	const {
		formData,
		name,
		label,
		traductionFunc,
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		typeFilterData: { valueInit, valueEnd, originalType },
		divClassName
	} = props;

	return (
		<FormGroup tag="fieldset" row className={divClassName}>
			<Col {...labelColumn}>
				<Label>{label}</Label>
			</Col>
			<Col {...inputColumn}>
				{formData && (
					<Row>
						<Col md="6" className="inputLabel">
							<DynamicFormInput
								{...props}
								label={traductionFunc('typeFilterInput.between')}
								name={`${name}__init`}
								value={formData[`${name}__init`] || valueInit}
								type={originalType}
							/>
						</Col>
						<Col md="6">
							<DynamicFormInput
								{...props}
								label={traductionFunc('typeFilterInput.and')}
								name={`${name}__finish`}
								value={formData[`${name}__finish`] || valueEnd}
								type={originalType}
							/>
						</Col>
					</Row>
				)}
			</Col>
		</FormGroup>
	);
}
