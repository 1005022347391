import React, { useEffect, useRef } from 'react';
import { FAST_SEARCHERS_WITH_ENTER } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardSearch = (props) => {
	const [t] = useTranslation('app');
	const { fields, setFields, setKeywords, placeholder = t('search') + '...', disabled, paramId } = props;
	const { location } = useHistory();
	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef.current && location.search) {
			const urlParams = Object.fromEntries(new URLSearchParams(location.search));
			const newSearchParam = urlParams[paramId] || urlParams.q;
			if (newSearchParam) {
				inputRef.current.value = newSearchParam;
				handleSearchBox({ target: { value: newSearchParam } }, true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearchBox = (e, forceEvent) => {
		if (FAST_SEARCHERS_WITH_ENTER === 0 || forceEvent) {
			setKeywords(e.target.value);
			if (typeof setFields !== 'function') return;
			if (typeof fields === 'string') return setFields(fields);
			const searchFields = fields
				? fields.map((field) => {
					return field.field;
				})
				: '';
			return setFields(searchFields.toString());
		} else {
			const { target: { value }, key } = e;
			if (key === 'Enter') {
				setKeywords(value);
				if (typeof setFields !== 'function') return;
				if (typeof fields === 'string') return setFields(fields);

				const searchFields = fields
					? fields.map((field) => {
						return field.field;
					})
					: '';
				return setFields(searchFields.toString());
			}
		}
	};

	return (
		<div className="search-wrapper d-flex align-items-center">
			<span className="icon-wrapper text-black">
				<FontAwesomeIcon icon={['fas', 'search']} />
			</span>
			{FAST_SEARCHERS_WITH_ENTER === 1 && (
				<input
					className="form-control form-control-sm rounded-pill"
					placeholder={placeholder}
					disabled={disabled}
					ref={(ref) => (inputRef.current = ref)}
					type="search"
					onKeyDown={handleSearchBox}
				/>
			)}
			{FAST_SEARCHERS_WITH_ENTER === 0 && (
				<input
					className="form-control form-control-sm rounded-pill"
					placeholder={placeholder}
					disabled={disabled}
					ref={(ref) => (inputRef.current = ref)}
					type="search"
					onChange={handleSearchBox}
				/>
			)}
		</div>
	);
};

export default CardSearch;
