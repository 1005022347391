import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from 'reactstrap';

import { isArrayEmpty } from '../../utils';
import DynamicForm from './DynamicForm';
import { useTranslation } from 'react-i18next';
import DynamicGrid from './TypeTabs/DynamicGrid';
import FormContext from '../../context/FormContext';
import ImatgesTab from './TypeTabs/ImatgesTab';
import LoadingSpinner from '../LoadingSpinner';
import HTMLReactParser from 'html-react-parser';

const dynamicGrids = ['tabOtTask', 'tabDocuments'];

const TabbedFormLayout = ({
	tabs,
	tabsContent,
	defaultTab,
	config,
	formErrors, // errors from main form
	submitHandler,
	updateFormData,
	formData,
	isLupaClicked,
	formTitle = undefined,
	formsState = undefined, //children's forms state
	isCreating,
	filterMode,
	alwaysEditableFields,
	showSaveBtn = true,
	originalData,
	isSearcher
}) => {
	const [activeTab, setActiveTab] = useState(defaultTab);
	const [t] = useTranslation('app');
	const { saveMassiveCreationData, loadingSubmit, inputsAlign } = useContext(FormContext);
	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const tabStatus = (id) => {
		if (formsState && !formsState[id]) return 'formTab-failed';
		if (formErrors) {
			const hasErrors =
				tabsContent && Array.isArray(tabsContent)
					? tabsContent
							.find((tabContent) => tabContent.id === id)
							?.body.some((field) => formErrors[field.name])
					: null;
			if (hasErrors) return 'formTab-failed';
		}
		// return 'formTab-success';
	};

	const renderDynamicCustomTab = (tabInfo, type) => {
		if (dynamicGrids.some((item) => item === type))
			return <DynamicGrid {...tabInfo} saveChange={saveMassiveCreationData} id={tabInfo.config_id} />;
		if (type === 'custom-gallery')
			return (
				<ImatgesTab
					{...tabInfo}
					saveChange={saveMassiveCreationData}
					config={tabInfo.options}
					title={tabInfo.name}
					id={type}
				/>
			);
	};

	return (
		<>
			<Card className="card-box">
				<form onSubmit={submitHandler}>
					<Row>
						<Col md="12">
							<div className="card-header py-3">
								<div className="card-header--title font-size-lg">
									{HTMLReactParser(formTitle || config?.name)}
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						{tabs.length > 1 && (
							<Col md="3">
								<Nav pills className="nav-neutral-primary flex-column p-3 text-black-50" tabs>
									{tabs.map(({ id, name }) => (
										<NavItem key={id} className={tabStatus(id)}>
											<NavLink
												onClick={() => toggleTab(id)}
												className={clsx({ active: activeTab === id })}>
												<span className="TabsForms-navTabs">{name}</span>
											</NavLink>
										</NavItem>
									))}
								</Nav>
							</Col>
						)}
						<Col md="9">
							<div className="p-4-5">
								<TabContent activeTab={activeTab}>
									{tabs.map((tabInfo, index) => {
										if (tabInfo.type) {
											const type = tabInfo.custom_id || tabInfo.type;
											return (
												<TabPane key={tabInfo.id} tabId={tabInfo.id}>
													{renderDynamicCustomTab(tabInfo, type)}
												</TabPane>
											);
										}
										const {
											id,
											tab = {},
											body,
											footer,
											conditionalFields
										} = tabsContent[index] || {};
										return (
											<TabPane key={id} tabId={id}>
												<div className="table-responsive">
													<Col md="4" className="  ">
														<p className="titolform">{tab.name}</p>
													</Col>
													<Col md="8"></Col>
												</div>
												{!isArrayEmpty(body) && (
													<div className="text-black-50">
														<DynamicForm
															onChange={updateFormData}
															inputs={body}
															formErrors={formErrors}
															formData={formData}
															name={id}
															isCreating={isCreating}
															filterMode={filterMode}
															alwaysEditableFields={alwaysEditableFields}
															conditionalFields={conditionalFields}
															originalData={originalData}
															isSearcher={isSearcher}
															allFields={config.fields}
															inputsAlign={inputsAlign}
															isEnrollment
															isIncidence
															isUsuari
															isOperations
														/>
													</div>
												)}
												{!isArrayEmpty(footer) && (
													<Card className="card-box bg-neutral-primary p-3 p-xl-4 mb-5">
														<DynamicForm
															onChange={updateFormData}
															isCreating={isCreating}
															conditionalFields={conditionalFields}
															inputs={footer}
															formData={formData}
															name={`formFooter-${id}`}
															filterMode={filterMode}
															alwaysEditableFields={alwaysEditableFields}
															originalData={originalData}
															isSearcher={isSearcher}
															allFields={config.fields}
															inputsAlign={inputsAlign}
														/>
													</Card>
												)}
											</TabPane>
										);
									})}
								</TabContent>
								<hr />
								{!isLupaClicked && showSaveBtn && (
									<div>
										<Button
											type="submit"
											// onClick={submitHandler}
											className="submitButton"
											disabled={loadingSubmit}>
											{filterMode ? t('search') : t('submit')}
											<LoadingSpinner show={loadingSubmit} />
										</Button>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</form>
			</Card>
		</>
	);
};

TabbedFormLayout.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	),
	tabsContent: PropTypes.arrayOf(PropTypes.any),
	defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	submitHandler: PropTypes.func.isRequired,
	updateFormData: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
	isLupaClicked: PropTypes.bool.isRequired
};

TabbedFormLayout.defaultProps = {
	tabs: [],
	tabsContent: [],
	defaultTab: 0
};

export default TabbedFormLayout;
