import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Card, CardBody } from 'reactstrap';
import { searchParams } from '../../services/commonServices';
import authenticatedFetcher from '../../services/fetcher';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import Create from '../Create';
import DynamicFormInput from '../DynamicForm/DynamicFormInput';

export default function CustomModalSections(props) {
	const { toggle, open, title, action, 'post-creation': postCreation, formContext, fetchData } = props;
	const [selectedSectionType, setSelectedSectionType] = useState(null);
	const [t] = useTranslation('app');

	const handleChange = ({ target: { value: optionValue } }) => {
		if( optionValue && optionValue['config-file'] ) {
			setSelectedSectionType(
				optionValue ? {
					...optionValue,
					'config-file': optionValue['config-file'].replace('config/', '')
				} : optionValue
			);
		}
	}

	const onSubmit = (data) => {
		const body = searchParams(postCreation, formContext ? formContext.data : {});
		authenticatedFetcher({
			path: postCreation.endpoint,
			method: postCreation.method,
			body: {
				...body,
				[postCreation.name_var_new]: data.id || data.pk,
				section_type: selectedSectionType.id || selectedSectionType.pk
			}
		}).then((res) => {
			if (res && res.error) {
				toastError(res.error);
			} else {
				toastSuccess(t('save'));
				fetchData();
				toggle();
			}
		});
	};

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{title}
			</ModalHeader>
			<ModalBody>
				<Card className="mb-3">
					<CardBody>
						<DynamicFormInput
							label="Tipo de sección"
							type="select"
							options={{ endpoint: action, print: 'name', key: 'config-file' }}
							onChange={handleChange}
							returnAll
							editable
						/>
					</CardBody>
				</Card>

				{selectedSectionType && (
					<Create
						key={selectedSectionType['config-file']}
						mode="form"
						toggle={toggle}
						id={selectedSectionType['config-file']}
						fetchData={onSubmit}
						returnRes
						withoutRelateds
					/>
				)}
			</ModalBody>
		</Modal>
	);
}

CustomModalSections.defaultProps = {
	toggle: () => {},
	updateData: () => {}
};
