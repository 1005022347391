import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


// https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router
const LeavePageBlocker = ({ when }) => {
  const { t } = useTranslation()
  const message = t('¿Está seguro que desea abandonar la página? Si no ha guardado los cambios, se perderán.')

  useEffect(() => {
    if (!when) return () => {}

    const beforeUnloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', beforeUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [when, message])

  return <Prompt when={when} message={message} />
}

LeavePageBlocker.propTypes = {
  when: PropTypes.bool.isRequired,
}

export default LeavePageBlocker