import React, {Fragment, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Sidebar, Header } from '../../layout-components';

const LeftSidebar = (props) => {
	const {
		children,
		sidebarToggle,
		sidebarToggleMobile,
		sidebarFixed,
		headerFixed,
		headerSearchHover,
		headerDrawerToggle,
		// footerFixed,
		contentBackground
	} = props;

	const [openMenu, setOpenMenu] = useState(false);

	const toggleMenu = () => setOpenMenu((prev) => !prev);

	const toggleMobileMenu = () => {
		if (typeof window !== 'undefined' && window.screen.width < 400) toggleMenu();
	};

	const noPrintLeftSidebar = (window.location.pathname === '/' && process.env.REACT_APP_CUSTOM_HOME);

	return (
		<>
			<div
				className={clsx('app-wrapper', contentBackground, {
					'header-drawer-open': headerDrawerToggle,
					'app-sidebar-collapsed': sidebarToggle,
					'app-sidebar-mobile-open': sidebarToggleMobile,
					'app-sidebar-fixed': sidebarFixed,
					'app-header-fixed': headerFixed,
					// 'app-footer-fixed': footerFixed,
					'search-wrapper-open': headerSearchHover,
				})}>
				{!noPrintLeftSidebar && (
					<Fragment>
						<div>
							<Sidebar openMenu={openMenu} toggleMenu={toggleMenu} toggleMobileMenu={toggleMobileMenu} />
						</div>
					</Fragment>
				)}
				<div className="app-main">
					<Header openMenu={openMenu} toggleMenu={toggleMenu} />
					<div className="app-content">
						<div className="app-content--inner">
							<div className="app-content--inner__wrapper">{children}</div>
						</div>
						{/* <Footer /> */}
					</div>
				</div>
			</div>
		</>
	);
};

LeftSidebar.propTypes = {
	children: PropTypes.node
};

export default LeftSidebar;
