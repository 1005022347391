import { buildCreateParams, validateConditions } from '../utils';
import { getMethods, searchParams } from '../services/commonServices';
import authenticatedFetcher from '../services/fetcher';
import { useEffect, useState } from 'react';

const fetchTabData = (key, urlParams = {}) => {
	return new Promise(async (resolve) => {
		try {
			const data = await authenticatedFetcher({ path: `${key}`, urlParams });
			resolve(data);
		} catch (error) {
			resolve(null);
		}
	});
};

export const useTabConfig = (tab, data, routes) => {
	const [tabData, setTabData] = useState();

	const getTabData = () => {
		const isTabValid = validateConditions(tab.conditions, data);
		if (isTabValid) {
			const urlParams = searchParams(tab, data);
			const {
				params: toCreateParams,
				paramsFixed: toCreateParamsFixed,
				reqParams
			} = buildCreateParams(tab['params-creation']);
			const reqParams2 = searchParams({ params: reqParams }, data);
			const dataUri = `/${tab.originConfig.endpoints.id}/`;
			fetchTabData(`${tab.originConfig.endpoints.list}`).then((response) => {
				const actions = getMethods(response.actions, routes);
				const newTabData = {
					title: tab.title,
					originConfig: tab,
					config: {
						...response,
						actions,
						toCreateModal: { fieldWithParams: reqParams2, data: tab.config.toCreateModal.data }
					},
					params: { ...urlParams, ...tab.params },
					uri: dataUri,
					in_creation_form: 'in_creation_form' in tab ? tab.in_creation_form : false
				};
				setTabData(newTabData);
			});
		}
	};

	useEffect(() => {
		if (tab) {
			getTabData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	return {
		tabData
	};
};
