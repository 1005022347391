import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import authenticatedFetcher from '../../services/fetcher';
import BasicSelect from '../BasicSelect';
import AppContext from '../../context';
import { useHistory } from 'react-router';
import { SET_GPARAMS_READY, UPDATE_GLOBAL_PARAMS } from '../../reducers/config-reducer/types';

const getGlobalFilters = ({ filters }) => {
	if (!Array.isArray(filters)) return {};
	return filters.reduce((acc, item) => {
		if (item.name_var === 'client') return { ...acc, clientFilter: item };
		return { ...acc, filters: acc.filter ? [...acc.filter, item] : [item] };
	}, {});
};

const GlobalFilters = (props) => {
	const { clientFilter, filters } = getGlobalFilters(props);
	const [filtersData, setFiltersData] = useState();
	const [clientData, setClientData] = useState();
	const history = useHistory();
	const mountedRef = useRef(true);

	const {
		config: { globalParams, app_home },
		dispatch
	} = useContext(AppContext);

	useEffect(() => {
		if (clientFilter)
			authenticatedFetcher({
				path: clientFilter.options.endpoint
			}).then((res) => {
				if (res && Array.isArray(res) && res.length && mountedRef.current) {
					setClientData(res);
				}
			});
		if (!Array.isArray(props.filters) || (Array.isArray(props.filters) && props.filters.length && !props.filters.some((item) => !item.hidden)))
			dispatch({
				type: SET_GPARAMS_READY,
				payload: true
			});
		return () => {
			mountedRef.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSave = (dataToSave) => {
		dispatch({
			type: UPDATE_GLOBAL_PARAMS,
			payload: dataToSave,
			mainParam: true
		});
		dispatch({
			type: SET_GPARAMS_READY,
			payload: true
		});
	};

	const refreshPage = () => {
		if (history.location.pathname === '/') history.go(0);
		else window.location.pathname = app_home || '/';
	};

	const handleClientChange = async ({ target: { value } }) => {
		let filtersRes = null;
		if (Array.isArray(filters)) {
			console.log(filters);
			let requests = [];
			filters.forEach((filter) =>
				requests.push(
					authenticatedFetcher({
						path: filter.options.endpoint,
						urlParams: { [clientFilter.name_var]: value }
					}).then((res) => {
						if (Array.isArray(res) && !!res.length) {
							let selected = res[0][filter.options.key];
							if (
								globalParams[filter.name_var] &&
								res.some((item) => item[filter.options.key] === +globalParams[filter.name_var])
							)
								selected = +globalParams[filter.name_var];

							return {
								selected,
								options: res,
								config: filter
							};
						}
						return null;
					})
				)
			);
			const res = await Promise.all(requests).catch((error) => {
				console.log('Error', error);
				return null;
			});
			if (Array.isArray(res)) filtersRes = res.filter((item) => !!item);
		}
		const newGlobalParams = filtersRes.reduce((acc, item) => ({ ...acc, [item.config.name_var]: item.selected }), {
			client: value
		});
		handleSave(newGlobalParams);
		if (globalParams.client === value || !globalParams.client) {
			setFiltersData(filtersRes);
		} else refreshPage();
	};

	const handleChange = ({ target: { value, name } }) => {
		handleSave({ [name]: value });
		refreshPage();
	};

	/* tienen que pintarse los filtros o no funcionan las páginas del motor.
		Por eso, si no los queremos los ocultamos por CSS. */
	let hiddenOptions = (process.env.REACT_APP_CUSTOM_HOME
		&& (window.location.pathname === '/' || globalParams['app'] === 'plani'));

	return (
		<div
			className={hiddenOptions
				? 'd-none'
				: 'd-flex flex-column flex-md-row mx-2 mx-md-0'}>
			{clientData && (
				<BasicSelect
					options={clientData}
					keyField={clientFilter.options.key}
					printField={clientFilter.options.print}
					name="client"
					onChange={handleClientChange}
					selectTheFirst={!globalParams.client}
					initialValue={globalParams.client}
					saveInitialValue={!!globalParams.client}
					stopListening="initialValue"
					className="mr-1 mb-1 small"
					withoutBlankOption
				/>
			)}
			{Array.isArray(filtersData) &&
				filtersData.map(({ options, config }, index) => {
					if (!config.hidden) {
						return (<BasicSelect
							key={index}
							options={options}
							keyField={config.options.key}
							printField={config.options.print}
							name={config.name_var}
							onChange={handleChange}
							className="mr-1 mb-1 small"
							initialValue={globalParams[config.name_var]}
							withoutBlankOption
						/>);
					}
				})}
			{props.children}
		</div>
	);
};

GlobalFilters.propTypes = {
	filters: PropTypes.array
};

export default GlobalFilters;
