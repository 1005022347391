import React, { useEffect, useRef, useState } from 'react';
import authenticatedFetcher from '../../services/fetcher';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import ckUploader from '../../services/ckUploader';

export const SarActionsTab = props => {
	const { userPk } = props;
	const editorRef = useRef();
	const config = {
		fontSize: {
			options: [
				9,
				11,
				13,
				'default',
				17,
				19,
				21,
				24,
				48
			]
		},
		toolbar: {
			items: [
				'Cut',
				'Copy',
				'Paste',
				'PastePlainText',
				'PasteFromWord',
				'|',
				'undo',
				'redo',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'|',
				'FontSize',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'blockQuote',
				'link',
				'mailTo',
				'|',
				'bulletedList',
				'numberedList',
				'|',
				'alignment',
				'|',
				'ImageUpload',
				'mediaEmbed',
				'HtmlEmbed',
				'|',
				'Subtitle',
				'Warning',
				'sourceEditing',
				'Maximize'
			]
		}
	};
	const date = new Date();
	const plus15Date = new Date();
	plus15Date.setDate(date.getDate() + 15);
	const [actionsList, setActionsList] = useState([]);
	const [actionPersonList, setActionPersonList] = useState([]);
	const [contactTypesList, setContactTypesList] = useState([]);
	const [defaultAnswerList, setDefaultAnswerList] = useState([]);
	const [recertificationGroupList, setRecertificationGroupList] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);

	const [creationDate, setCreationDate] = useState(`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`);
	const [selectedActionPerson, setSelectedActionPerson] = useState(userPk ?? undefined);
	const [isPublicAction, setPublicAction] = useState('1');
	const [selectedContactType, setSelectedContactType] = useState();
	const [selectedDefaultAnswer, setSelectedDefaultAnswer] = useState();
	const [selectedRecertificationGroup, setSelectedRecertificationGroup] = useState();
	const [controlDate, setControlDate] = useState(`${plus15Date.getFullYear()}-${('0' + (plus15Date.getMonth() + 1)).slice(-2)}-${('0' + plus15Date.getDate()).slice(-2)}`);
	const [selectedDepartment, setSelectedDepartment] = useState();
	const [comments, setComments] = useState('');

	const [sar, setSar] = useState();

	const handleChangeCreationDate = (event) => setCreationDate(event.target.value);
	const handleChangeSelectedActionPerson = (event) => setSelectedActionPerson(event.target.value);
	const handleChangeIsPublicAction = (event) => setPublicAction(event.target.value);
	const handleChangeSelectedContactType = (event) => setSelectedContactType(event.target.value);
	const handleChangeSelectedDefaultAnswer = (event) => setSelectedDefaultAnswer(event.target.value);
	const handleChangeSelectedRecertificationGroup = (event) => setSelectedRecertificationGroup(event.target.value);
	const handleControlDateChange = (event) => setControlDate(event.target.value);
	const handleChangeSelectedDepartment = (event) => setSelectedDepartment(event.target.value);

	const onSubmit = (e) => {
		e.preventDefault();
		authenticatedFetcher({
			path: '/sar/',
			body: {
				action_date: creationDate,
				action_person: selectedActionPerson,
				is_private: isPublicAction,
				person: selectedActionPerson,
				contact_type: selectedContactType,
				default_answer: selectedDefaultAnswer,
				recertification_group: selectedRecertificationGroup,
				control_date: controlDate,
				department: selectedDepartment,
				comments: comments
			},
			method: 'POST'
		}).then(() => {
			if (userPk) fetchActionsList();
		});
	};

	const fetchActionsList = () => {
		authenticatedFetcher({
			path: `/sar/${userPk}/list/`
		}).then(response => {
			setActionsList(response);
		});
	};
	const fetchActionPerson = () => {
		authenticatedFetcher({
			path: '/persons/',
			urlParams: {
				is_staff: 1,
				nitems: 500,
				order: 'full_name',
				order_type: 'asc'
			}
		}).then(response => {
			setActionPersonList(response);
		});
	};
	const fetchContactTypes = () => {
		authenticatedFetcher({
			path: '/sar/contact-types/',
			urlParams: {
				nitems: 500
			}
		}).then(response => {
			setContactTypesList(response);
			if (response.length > 0) {
				setSelectedContactType(response[0].pk);
			}
		});
	};
	const fetchDefaultAnswers = () => {
		authenticatedFetcher({
			path: '/sar/default-answers/',
			urlParams: {
				nitems: 500
			}
		}).then(response => {
			setDefaultAnswerList(response);
		});
	};
	const fetchRecertificationGroups = () => {
		authenticatedFetcher({
			path: '/persons/recertification-group/',
			urlParams: {
				nitems: 500,
				person: selectedActionPerson
			}
		}).then(response => {
			setRecertificationGroupList(response);
			if (response.length > 0) {
				setSelectedRecertificationGroup(response[0].pk);
			}
		});
	};
	const fetchDepartments = () => {
		authenticatedFetcher({
			path: '/sar/departments/',
			urlParams: {
				nitems: 500
			}
		}).then(response => {
			setDepartmentList(response);
			if (response.length > 0) {
				setSelectedDepartment(response[0].pk);
			}
		});
	};
	const getAction = (sarId) => {
		setSar(undefined);
		authenticatedFetcher({
			path: `/sar/${sarId}`
		}).then(response => {
			setCreationDate(response.action_date);
			setSelectedActionPerson(response.person);
			setSelectedContactType(response.contact_type);
			setSelectedDefaultAnswer(response.default_answer);
			setSelectedRecertificationGroup(response.recertification_group);
			setControlDate(response.control_date);
			setSelectedDepartment(response.department);
			setComments(response.comments);
			setSar(response);
			fetchActionsList();
		});
	};

	const handleOnInit = (editor) => {
		editorRef.current = editor;
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return new ckUploader(loader);
		};

	};

	const handleChangeEditor = (event, editor) => {
		const data = editor.getData();
		// handleChange({ target: { data, name: 'body' } });
		setComments(data);
	};

	useEffect(() => {
		setSelectedContactType(undefined);
		setSelectedDefaultAnswer(undefined);
		setSelectedRecertificationGroup(undefined);
		setSelectedDepartment(undefined);
		setComments('');
		setSar(undefined);
		if (userPk) {
			fetchActionsList();
		}
		fetchRecertificationGroups();
		fetchActionPerson();
		fetchContactTypes();
		fetchDefaultAnswers();
		fetchRecertificationGroups();
		fetchDepartments();
	}, [userPk, selectedActionPerson]);
	return <div className="card-body">
		<form onSubmit={onSubmit}>
			<div className="form-row">
				<div className="col-md-4 form-group">
					<label htmlFor="dataAccio">Fecha Acción</label>
					<input type="date" className="form-control BasicInput" id="dataAccio"
						   value={creationDate} onChange={handleChangeCreationDate} />

					<label htmlFor="usuari" className="mt-2">Usuario</label>
					<select className="form-control BasicInput" aria-label=".form-select-lg example"
							value={selectedActionPerson} onChange={handleChangeSelectedActionPerson}>
						{
							actionPersonList.map(item => {
								return <option key={item.pk} value={item.pk}>{item.full_name}</option>;
							})
						}
					</select>

					<label htmlFor="accionNoPublica" className="mt-2">¿Acción NO pública?</label>
					<select className="form-control BasicInput" aria-label=".form-select-lg example"
							id="accionNoPublica" value={isPublicAction} onChange={handleChangeIsPublicAction}>
						<option value="0">No</option>
						<option value="1">Si</option>
					</select>

					<label htmlFor="canal" className="mt-2">Canal</label>
					<select className="form-control BasicInput" aria-label=".form-select-lg example" id="canal"
							value={selectedContactType} onChange={handleChangeSelectedContactType}>
						{
							contactTypesList.map(item => {
								return <option key={item.pk} value={item.pk}>{item.name}</option>;
							})
						}
					</select>

					<label htmlFor="respostaPredeterminada" className="mt-2">Respuesta
						predeterminada</label>
					<select className="form-control BasicInput" aria-label=".form-select-lg example"
							id="respostaPredeterminada"
							value={selectedDefaultAnswer} onChange={handleChangeSelectedDefaultAnswer}>
						<option key="-" value=""></option>
						{
							defaultAnswerList.map(item => {
								return <option key={item.pk} value={item.pk}>{item.name}</option>;
							})
						}
					</select>

					<label htmlFor="dataControl" className="mt-2">Recertificación</label>
					<select className="form-control BasicInput" aria-label=".form-select-lg example" id="dataControl"
							value={selectedRecertificationGroup} onChange={handleChangeSelectedRecertificationGroup}>
						{
							recertificationGroupList.map(item => {
								return <option key={item.pk} value={item.pk}>{item.recertification_batch_name}</option>;
							})
						}
					</select>

					<label htmlFor="dataControl2" className="mt-2">Fecha Control</label>
					<input type="date" className="form-control BasicInput" id="dataControl2"
						   value={controlDate} onChange={handleControlDateChange} />

					<label htmlFor="departamento" className="mt-2">Departamento</label>
					<select className="form-control BasicInput" aria-label=".form-select-lg example" id="departamento"
							value={selectedDepartment} onChange={handleChangeSelectedDepartment}>
						{
							departmentList.map(item => {
								return <option key={item.pk} value={item.pk}>{item.name}</option>;
							})
						}
					</select>
					{
						sar && <>
							<label htmlFor="hours" className="mt-2">Horas</label>
							<input type="default" className="form-control BasicInput" id="hours"
								   value={sar.hours} readOnly />
						</>
					}
					{
						sar && <>
							<label htmlFor="cert" className="mt-2">Certificado</label>
							<input type="default" className="form-control BasicInput" id="cert"
								   value={sar.certification_name} readOnly />
						</>
					}
				</div>

				<div className="col-md-8 form-group">
					<label htmlFor="observacions">Observaciones</label>
					<CKEditor
						editor={Editor}
						data={comments}
						onReady={handleOnInit}
						onChange={handleChangeEditor}
						name="observacions"
						config={config}
					/>
				</div>
			</div>
			<div className="text-right mt-3">
				<button style={{ cursor: 'pointer' }} type="submit" className="btn btn-secondary mr-1">
					Guardar
				</button>
			</div>
		</form>
		<div style={{ maxHeight: '250px' }} className="table-responsive mt-3">
			<table className="table table-bordered">
				<thead className="thead-light">
				<tr>
					<th>Acción</th>
					<th>Usuario</th>
					<th>Canal</th>
					<th>Respuesta Predeterminada</th>
					<th>Control</th>
					<th>Horas</th>
					<th>Certificación</th>
				</tr>
				</thead>
				<tbody style={{ overflowY: 'auto' }}>
				{
					actionsList.map(item => {
						return <tr>
							<td>
								<button
									type="button"
									className="btn"
									style={{
										fontSize: 11,
										textDecoration: 'underline',
										color: 'blue'
									}}
									onClick={() => getAction(item.pk)}
								>{item.created_at}</button>
							</td>
							<td>{item.action_person_name}</td>
							<td>{item.contact_type_name}</td>
							<td>{item.default_answer_name}</td>
							<td>{item.control_date}</td>
							<td>{item.hours}</td>
							<td>{item.certification_name}</td>
						</tr>;
					})
				}
				</tbody>
			</table>
		</div>
	</div>;
};