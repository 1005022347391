import React, { Fragment } from 'react';
import CustomPagesBlock from '../../CustomPagesBlock';
import List from '../../List';
import ListBlock from '../../ListBlock';
import TypeTabs from './TypeTabs';

const availableRelatedCards = {
	tabs: TypeTabs
};

const DynamicFormGroups = (props) => {
	const { groupedData, showMode, withFormContext, isCreating, className } = props;
	const RelatedMode = availableRelatedCards[showMode];
	if (RelatedMode) return <RelatedMode {...props} />;
	return groupedData.map((gd, i) => (
		<Fragment key={`RelatedCard-${i}`}>
			{gd.tabs.map((t, i) => {
				if (isCreating && !t.in_creation_form) return null;
				const groupClass = className ? className : i === 0 ? undefined : 'mt-5';
				switch (gd.type) {
					case 'listBlock':
						return (
							<ListBlock
								key={i}
								id={t.uri}
								tab={t}
								className={groupClass}
								relatedID={`${gd.id}-${i}`}
								withoutMargin
								withoutPlusBtn
								withoutButtonsBar
								initialConfig={t.originConfig}
								withContext
							/>
						);
					case 'form':
						return (
							withFormContext && (
								<CustomPagesBlock key={i} {...t} relatedID={`${gd.id}-${i}`} className={groupClass} />
							)
						);
					default:
						return (
							<List
								key={i}
								config={t.config}
								params={t.params}
								id={t.uri}
								tab={t}
								customName={t.originConfig.title_fixed}
								className={groupClass}
								relatedID={`${gd.id}-${i}`}
								withoutMargin
								withoutPlusBtn={
									'button_creation' in t.originConfig ? !t.originConfig.button_creation : false
								}
								withoutButtonsBar={
									'print_buttons' in t.originConfig ? !t.originConfig.print_buttons : false
								}
							/>
						);
				}
			})}
		</Fragment>
	));
};

export default DynamicFormGroups;
