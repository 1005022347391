import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Row, Col } from 'reactstrap';

import { isArrayEmpty } from '../../utils';
import DynamicForm from './DynamicForm';
import { useTranslation } from 'react-i18next';
import FormContext from '../../context/FormContext';
import LoadingSpinner from '../LoadingSpinner';

const ColumnedFormLayout = ({
	tabsContent,
	config,
	formErrors,
	submitHandler,
	updateFormData,
	formData,
	isLupaClicked = false,
	formTitle = undefined,
	isCreating,
	filterMode,
	alwaysEditableFields,
	showSaveBtn = true,
	originalData,
	isSearcher
}) => {
	const { layout } = config;
	const [t] = useTranslation('app');
	const { loadingSubmit, inputsAlign } = useContext(FormContext);
	return (
		<>
			<Card className="card-box">
				<form onSubmit={submitHandler}>
					<div className="card-header py-3">
						<div className="card-header--title font-size-lg">{formTitle || config?.name}</div>
					</div>
					<div className="card-body">
						<Row>
							{tabsContent.map(({ id, tab, body, footer, conditionalFields }, index) => (
								<Col key={id} className={layout.columns || 'col-md-6'}>
									<p className="titolform">{tab.name}</p>
									{!isArrayEmpty(body) && (
										<div className="text-black-50">
											<DynamicForm
												onChange={updateFormData}
												isCreating={isCreating}
												alwaysEditableFields={alwaysEditableFields}
												conditionalFields={conditionalFields}
												inputs={body}
												formErrors={formErrors}
												formData={formData}
												name={`form-${index}`}
												filterMode={filterMode}
												originalData={originalData}
												isSearcher={isSearcher}
												allFields={config.fields}
												inputsAlign={inputsAlign}
											/>
										</div>
									)}
									{!isArrayEmpty(footer) && (
										<Card className="card-box bg-neutral-primary p-3 p-xl-4 mb-5">
											<DynamicForm
												onChange={updateFormData}
												isCreating={isCreating}
												inputs={footer}
												formData={formData}
												name={`formFooter-${index}`}
												filterMode={filterMode}
												alwaysEditableFields={alwaysEditableFields}
												conditionalFields={conditionalFields}
												originalData={originalData}
												isSearcher={isSearcher}
												allFields={config.fields}
												inputsAlign={inputsAlign}
											/>
										</Card>
									)}
								</Col>
							))}
						</Row>
					</div>
					{!isLupaClicked && showSaveBtn && (
						<div className="card-footer">
							<Button type="submit" className="submitButton" disabled={loadingSubmit}>
								{filterMode ? t('search') : t('submit')}
								<LoadingSpinner show={loadingSubmit} />
							</Button>
						</div>
					)}
				</form>
			</Card>
		</>
	);
};

ColumnedFormLayout.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	),
	tabsContent: PropTypes.arrayOf(PropTypes.any),
	defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	submitHandler: PropTypes.func.isRequired,
	updateFormData: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
	isLupaClicked: PropTypes.bool
};

export default ColumnedFormLayout;
