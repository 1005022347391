import React, {Fragment} from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

import { NavLink } from 'react-router-dom';
import { APP_TITLE } from '../../config';

// import projectLogo from '../../assets/images/logoefpa.jpg';
// import projectLogo from '../../assets/images/react.svg';

const SidebarHeader = (props) => {
	const projectLogo = process.env.REACT_APP_IMAGE_BAR;
	const { toggleMenu, openMenu } = props;

	return (
		<div>
			<div className="app-sidebar--header" style={{backgroundColor: "#fff"}}>
				<div className="app-sidebar-logo">
					<NavLink to="/" title={APP_TITLE} className="app-sidebar-logo">
						<img alt={APP_TITLE} src={projectLogo} style={{ width: '182px' }} />
					</NavLink>
				</div>

				{!(window.location.pathname === '/' && process.env.REACT_APP_CUSTOM_HOME) && (
					<Fragment>
						<button
							className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
								'is-active': openMenu
							})}
							onClick={toggleMenu}>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>
						<button onClick={toggleMenu} className="expand-sidebar-btn btn btn-sm" id="ExpandSidebarTooltip">
							<FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
						</button>
						<UncontrolledTooltip target="ExpandSidebarTooltip" container=".app-sidebar" placement="right">
							Expand sidebar
						</UncontrolledTooltip>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default SidebarHeader;
