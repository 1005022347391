import React, { useEffect, useState, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Label, Button } from 'reactstrap';
import authenticatedFetcher from '../../services/fetcher';
import { fileUploader } from '../../services/commonServices';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { MEDIA_BASE_URL } from '../../config';
import LoadingSpinner from '../LoadingSpinner';
import { useTranslation } from 'react-i18next';
import htmlParse from "html-react-parser";


export default function CustomModalImportEfpaMagazine(props) {
	const { toggle, open } = props;
	const [resetCount, setResetCount] = useState(0);
	const [t] = useTranslation('app');
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [formData, setFormData] = useState({
		date_limit: ""
	});

	useEffect(() => {
	}, []);

	const handleSubmit = async () => {
		setLoadingSubmit(true);
		authenticatedFetcher({
			path: '/magazine-guests/update-magazine-reception/',
			method: 'POST',
			body: formData
		})
			.then((res) => {
				if ('error' in res) {
					toastError(
						htmlParse(res.error),
						{ autoClose: false }
					);
				} else {
					toastSuccess(
						res.response,
						{ autoClose: 'fixed_message' in res ? !res.fixed_message : true },
						true
					);

				}

				setLoadingSubmit(false);
			})
			.catch(() => setLoadingSubmit(false));

		/*const res = await authenticatedFetcher({
			path: '/persons/update-magazine-reception/',
			method: 'POST',
			body: formData
		});
		setLoadingSubmit(false);
		if (res?.action) {
			toastSuccess(res.action);
		} else {
			toastError('Error');
		}*/
	};

	const handleFileChange = async (inputEvent) => {
		const fileUploaded = await fileUploader(inputEvent);
		if (!fileUploaded) return;
		setFormData((prev) => ({ ...prev, ...fileUploaded }));
		inputEvent.value = '';
	};

	const handleFieldChange = ({ target: { value, name } }) =>
		setFormData((prev) => ({ ...prev, [name]: Array.isArray(value) ? value.join(',') : value }));

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				Cambio a recepción por email de la Revista EFPA
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="dynamic-form">
							<div className="row align-items-center">
								<div className="col-12 col-md-2">
									<Label className="PartnerCourse-label" for={'entidad'}>
										{t('modals.template')}:
									</Label>
								</div>
								<div className="col-12 col-md-10">
									<a
										href={`${MEDIA_BASE_URL}/media/revista/modificaciones-revista-formato.xlsx`}
										target="_blank"
										rel="noopener noreferrer">
										{t('modals.donwload_sample_template')}
									</a>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-12 col-md-2">
									<Label className="PartnerCourse-label" for={'entidad'}>
										Fecha límite:
									</Label>
								</div>
								<div className="col-12 col-md-4">
									<input
										className="form-control"
										type="date"
										name="date_limit"
										onChange={handleFieldChange}
									/>
									<Label style={{fontStyle: "italic", fontSize: "11px", paddingTop: "10px", textTransform: "none"}}>
										Los que hayan modificado sus datos a partir de esta fecha no se modificarán
									</Label>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-12 col-md-2">
									<Label className="PartnerCourse-label" for={'entidad'}>
										Archivo:
									</Label>
								</div>
								<div className="col-12 col-md-10">
									<span>{formData['file']}</span>
									<input
										type="file"
										key={`file-${resetCount}-${formData['file']}`}
										name="file"
										onChange={handleFileChange}
									/>
									<Label style={{fontStyle: "italic", fontSize: "11px", paddingTop: "10px", textTransform: "none"}}>
										El archivo tiene que tener un formato XLSX
									</Label>
								</div>
							</div>

							<Button type="submit" className="submitButton mt-4" onClick={handleSubmit}>
								Actualizar
								<LoadingSpinner show={loadingSubmit} />
							</Button>
						</div>

					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
