import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'reactstrap';
import parse from 'html-react-parser';

import Create from './Create';
import ExportModal from './ExportModal';
import ListContext from './List/ListContext';
import { useTranslation } from 'react-i18next';

const TableHeader = ({
	id,
	name,
	advanced_search,
	add,
	fetchData,
	export: withBtnExcel,
	customName,
	withoutPlusBtn,
	exportConfig = {},
	actions,
	createModalProps = {},
	advSearchConfig
}) => {
	const [firstRender, setFirstRender] = useState(true);
	const [toggle, setToggle] = useState(false);
	const [toggleExport, setToggleExport] = useState(false);
	const [toggleFilter, setToggleFilter] = useState(false);
	const { urlParams, advancedSearchForm, setAdvancedSearchForm } = useContext(ListContext);
	const { write: writeMethod } = actions;
	const [t] = useTranslation('app');
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [tooltipExport, setTooltipExport] = useState(false);
	const [tooltipAdd, setTooltipAdd] = useState(false);

	const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
	const toggleTooltipExport = () => setTooltipExport(!tooltipExport);
	const toggleTooltipAdd = () => setTooltipAdd(!tooltipAdd);

	const toggleModal = () => {
		setToggle((prev) => !prev);
		localStorage.setItem('isLupaClicked', false);
	};

	const toggleFilterModal = () => setToggleFilter((prev) => !prev);

	const toggleExportModal = () => setToggleExport((prev) => !prev);

	const renderBtnExcel = () => {
		if (!withBtnExcel) {
			return <Fragment />;
		}
		return (
			<Fragment>
				<Button className="BtnAdvanceSearch" size="sm" onClick={toggleExportModal} id="TooltipExport">
					<i className="fas fa-file-export"></i>
				</Button>
				<Tooltip placement="bottom" isOpen={tooltipExport} target="TooltipExport" toggle={toggleTooltipExport}>
					{t('tableActions.export')}
				</Tooltip>
			</Fragment>
		);
	};

	const handleChangeAdvFilters = (formData) =>
		setAdvancedSearchForm(
			Object.entries(formData || {}).reduce(
				(acc, [key, value]) => (typeof value === 'boolean' || value ? { ...acc, [key]: value } : acc),
				{}
			)
		);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
			return;
		}
		toggleModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createModalProps]);

	return (
		<>
			<div className="card-header py-3">
				<div className="card-header--title font-size-lg">
					<span>{customName ? parse(customName) : name}</span>
				</div>
				<div className="card-header--actions">
					{advanced_search && (
						// 	<Button size="sm" color="success" onClick={toggleFilterModal}>
						// 	<span className="btn-wrapper--icon">
						// 		<FontAwesomeIcon icon={['fas', 'search']} className="text-light" />
						// 	</span>
						// 	{/* <span className="btn-wrapper--label">Añadir</span> */}
						// </Button>
						<Fragment>
							<Button
								className="BtnAdvanceSearch"
								size="sm"
								color="success"
								onClick={toggleFilterModal}
								id="TooltipExample">
								<i className="fas fa-search"></i>
							</Button>
							<Tooltip
								placement="bottom"
								isOpen={tooltipOpen}
								target="TooltipExample"
								toggle={toggleTooltip}>
								{t('tableActions.search')}
							</Tooltip>
						</Fragment>
					)}
					{renderBtnExcel()}
					{/* <FontAwesomeIcon icon={['fas', 'ellipsis-v']} className="font-size-lg" /> */}
					{add && !withoutPlusBtn && (
						<Fragment>
							<Button className="BtnAdvanceSearch" size="sm" onClick={toggleModal} id="TooltipAdd">
								<i className="fas fa-plus-circle"></i>
								{/* <span className="btn-wrapper--label">Añadir</span> */}
							</Button>
							<Tooltip
								placement="bottom"
								isOpen={tooltipAdd}
								target="TooltipAdd"
								toggle={toggleTooltipAdd}>
								{t('tableActions.add')}
							</Tooltip>
						</Fragment>
					)}
				</div>
				{/* Modal to export */}
				{toggleExport && (
					<ExportModal
						id={writeMethod?.configUri || id}
						urlParams={urlParams}
						actions={actions}
						exportConfig={exportConfig}
						toggle={toggleExportModal}
					/>
				)}
				{/*Modal to create*/}
				{toggle && (
					<Create
						{...createModalProps}
						toggle={toggleModal}
						id={writeMethod?.configUri || id}
						fetchData={fetchData}
					/>
				)}
				{/*Modal for advanced filters*/}
				{toggleFilter && (
					<Create
						toggle={toggleFilterModal}
						id={id || writeMethod?.configUri}
						fetchData={handleChangeAdvFilters}
						data={advancedSearchForm || {}}
						customConfig={advSearchConfig}
						filterMode
						withoutRelateds
					/>
				)}
			</div>
		</>
	);
};

TableHeader.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	add: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	fetchData: PropTypes.func.isRequired
};

TableHeader.defaultProps = {
	edit: false,
	copy: false,
	trash: false,
	arrow: false,
	add: false
};

export default TableHeader;
