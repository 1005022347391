import React, { useEffect, useState } from 'react';
import authenticatedFetcher from '../../services/fetcher';

export const SarDataRecoveryForm = props => {
	const { userPk } = props;
	const [userData, setUserData] = useState();

	const resetFields = () => {
		const documents = document.querySelectorAll('input[readonly]');
		for (let doc of documents) {
			doc.value = ''
		}
	}
	const fetchPersonData = () => {
		authenticatedFetcher({
			path: `/persons/${userPk}/sar-person-data/`
		}).then(response => {
			resetFields();
			setUserData(response);
		});
	};
	useEffect(() => {
		if (userPk) fetchPersonData();
	}, [userPk]);
	return <form>
		<div className="form-row">
			<div className="col-md-3 form-group">
				<input type="text" className="form-control BasicInput" id="nombre"
					   value={userData?.first_name} readOnly />
			</div>
			<div className="col-md-3 form-group">
				<input type="text" className="form-control BasicInput" id="apellido"
					   value={userData?.last_name} readOnly />
			</div>
			<div className="col-md-3 form-group">
				<input type="text" className="form-control BasicInput" id="segundo-apellido"
					   value={userData?.second_last_name} readOnly />
			</div>
			<div className="col-md-3 form-group">
				<input type="text" className="form-control BasicInput" id="entidad"
					   value={userData?.enterprise_name} readOnly />
			</div>
		</div>
		<div className="form-row">
			<div className="col-md-3 form-group">
				<label htmlFor="nif">NIF</label>
				<input type="text" className="form-control BasicInput" id="nif"
					   value={userData?.identity_document} readOnly />
			</div>
			<div className="col-md-3 form-group">
				<label htmlFor="nombre">Tel Prof 1</label>
				<input type="text" className="form-control BasicInput" id="nombre"
					   value={userData?.professional_phone} readOnly />
			</div>
			<div className="col-md-3 form-group">
				<label htmlFor="apellido">Tel Cont. 1</label>
				<input type="text" className="form-control BasicInput" id="apellido"
					   value={userData?.contact_phone} readOnly />
			</div>
			<div className="col-md-3 form-group">
				<label htmlFor="entidad">Correo</label>
				<input type="text" className="form-control BasicInput" id="entidad"
					   value={userData?.email} readOnly />
			</div>
		</div>
		<div className="form-row">
			<div className="col-md-2 form-group">
				<label htmlFor="telefono1">Tel Prof 2</label>
				<input type="text" className="form-control BasicInput" id="telefono1"
					   value={userData?.professional_phone_secondary} readOnly />
			</div>
			<div className="col-md-2 form-group">
				<label htmlFor="telefono2">Tel Cont. 2</label>
				<input type="text" className="form-control BasicInput" id="telefono2"
					   value={userData?.contact_phone_secondary} readOnly />
			</div>
		</div>
		<div className="form-row justify-content-between">
			<div className="col-md-2 form-group">
				<label htmlFor="titulo">Num. Miembro</label>
				<input type="text" className="form-control BasicInput" id="titulo"
					   value={userData?.mifid_data?.code} readOnly
					   style={{ background: userData?.mifid_data?.is_drop ? 'red' : userData?.mifid_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.lcci_data?.code} readOnly
					   style={{ background: userData?.lcci_data?.is_drop ? 'red' : userData?.lcci_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.esg_data?.code} readOnly
					   style={{ background: userData?.esg_data?.is_drop ? 'red' : userData?.esg_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.insurance_data?.code} readOnly
					   style={{ background: userData?.insurance_data?.is_drop ? 'red' : userData?.insurance_data?.is_warning ? 'yellow' : undefined }} />
			</div>
			<div className="col-md-3 form-group">
				<label htmlFor="fechaInicio">Certificado</label>
				<input type="text" className="form-control BasicInput" id="fechaInicio"
					   value={userData?.mifid_data?.contact_level_name} readOnly
					   style={{ background: userData?.mifid_data?.is_drop ? 'red' : userData?.mifid_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.lcci_data?.contact_level_name} readOnly
					   style={{ background: userData?.lcci_data?.is_drop ? 'red' : userData?.lcci_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.esg_data?.contact_level_name} readOnly
					   style={{ background: userData?.esg_data?.is_drop ? 'red' : userData?.esg_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.insurance_data?.contact_level_name} readOnly
					   style={{ background: userData?.insurance_data?.is_drop ? 'red' : userData?.insurance_data?.is_warning ? 'yellow' : undefined }} />
			</div>
			<div className="col-md-2 form-group">
				<label htmlFor="horas">Fecha</label>
				<input type="text" className="form-control BasicInput" id="horas"
					   value={userData?.mifid_data?.group_name} readOnly
					   style={{ background: userData?.mifid_data?.is_drop ? 'red' : userData?.mifid_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.lcci_data?.group_name} readOnly
					   style={{ background: userData?.lcci_data?.is_drop ? 'red' : userData?.lcci_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.esg_data?.group_name} readOnly
					   style={{ background: userData?.esg_data?.is_drop ? 'red' : userData?.esg_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.insurance_data?.group_name} readOnly
					   style={{ background: userData?.insurance_data?.is_drop ? 'red' : userData?.insurance_data?.is_warning ? 'yellow' : undefined }} />
			</div>
			<div className="col-md-1 form-group">
				<label style={{ fontSize: '9px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
					   htmlFor="realizadas">
					Horas Requeridas
				</label>
				<input type="text" className="form-control BasicInput" id="realizadas"
					   value={userData?.mifid_data?.hours_to_perform} readOnly
					   style={{ background: userData?.mifid_data?.is_drop ? 'red' : userData?.mifid_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.lcci_data?.hours_to_perform} readOnly
					   style={{ background: userData?.lcci_data?.is_drop ? 'red' : userData?.lcci_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.esg_data?.hours_to_perform} readOnly
					   style={{ background: userData?.esg_data?.is_drop ? 'red' : userData?.esg_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.insurance_data?.hours_to_perform} readOnly
					   style={{ background: userData?.insurance_data?.is_drop ? 'red' : userData?.insurance_data?.is_warning ? 'yellow' : undefined }} />
			</div>
			<div className="col-md-1 form-group">
				<label style={{ fontSize: '9px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
					   htmlFor="colegiado2">
					Horas Realizadas
				</label>
				<input type="text" className="form-control BasicInput" id="colegiado2"
					   value={userData?.mifid_data?.hours_performed} readOnly
					   style={{ background: userData?.mifid_data?.is_drop ? 'red' : userData?.mifid_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.lcci_data?.hours_performed} readOnly
					   style={{ background: userData?.lcci_data?.is_drop ? 'red' : userData?.lcci_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.esg_data?.hours_performed} readOnly
					   style={{ background: userData?.esg_data?.is_drop ? 'red' : userData?.esg_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.insurance_data?.hours_performed} readOnly
					   style={{ background: userData?.insurance_data?.is_drop ? 'red' : userData?.insurance_data?.is_warning ? 'yellow' : undefined }} />
			</div>
			<div className="col-md-1 form-group">
				<label style={{ fontSize: '9px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
					   htmlFor="colegiado2">
					Código ético web
				</label>
				<input type="text" className="form-control BasicInput" id="colegiado2"
					   value={userData?.mifid_data ? userData?.mifid_data?.signed ? 'Si' : 'No' : ''} readOnly
					   style={{ background: userData?.mifid_data?.is_drop ? 'red' : userData?.mifid_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.lcci_data ? userData?.lcci_data?.signed ? 'Si' : 'No' : ''} readOnly
					   style={{ background: userData?.lcci_data?.is_drop ? 'red' : userData?.lcci_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.esg_data ? userData?.esg_data?.signed ? 'Si' : 'No' : ''} readOnly
					   style={{ background: userData?.esg_data?.is_drop ? 'red' : userData?.esg_data?.is_warning ? 'yellow' : undefined }} />
				<input type="text" className="form-control BasicInput mt-2" id="titulo"
					   value={userData?.insurance_data ? userData?.insurance_data?.signed ? 'Si' : 'No' : ''} readOnly
					   style={{ background: userData?.insurance_data?.is_drop ? 'red' : userData?.insurance_data?.is_warning ? 'yellow' : undefined }} />
			</div>
		</div>
	</form>;
};