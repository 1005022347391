import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import OptionsTabs from '../OptionsTabs';

const labelField = 'name';

const MarkerPopup = ({
	dragOptions: { draggable = false, toggleDraggable = () => {}, onCancel = () => {} } = {},
	popupStructure = [],
	popupData = {},
	popupOptions = {},
	loading = true,
	isEditing = true,
	onChangeSelectedMarker = () => {},
	selectedMarker
}) => {
	const { withDrag = true } = popupOptions ? popupOptions : {};
	const { t } = useTranslation('map');
	const history = useHistory();

	const handleInventaryClick = () => {
		const data = popupData.items[selectedMarker];
		history.push(`/${data.custom_page}/${data.pk}`);
	};

	const onClickTicketing = () => {
		const data = popupData.items[selectedMarker];
		history.push(`/ticketing/ots/?q=${data[labelField]}`);
	};

	const renderDetails = () => {
		const data = popupData.items[selectedMarker];
		return popupStructure.map(({ label, value, itemStyle }, i) => {
			return (
				<div className="MarkerPopup-detail" key={`detail-${i}`} style={itemStyle}>
					<strong>{label}</strong>
					<span>{data[value]}</span>
				</div>
			);
		});
	};

	const renderBody = () => {
		if (loading) return <p>{t('app:loading')}</p>;
		if (!popupData?.items || !Array.isArray(popupStructure)) return;
		return (
			<Fragment>
				<div className="MarkerPopup-actiuTitle">
					Actius {popupData.items.length > 1 ? `(${popupData.items.length})` : ''}
				</div>
				<div className="MarkerPopup-wrapper">
					<div className={popupData.items.length > 1 ? 'MarkerPopup-navbar' : 'd-none'}>
						<OptionsTabs
							options={popupData.items}
							selected={selectedMarker}
							labelField={labelField}
							onChange={onChangeSelectedMarker}
							disableUnselected={draggable}
						/>
					</div>
					<div className="MarkerPopup-container">
						<div className="MarkerPopup-details">{renderDetails()}</div>
						<div className="MarkerPopup-buttons">
							{withDrag ? (
								<Fragment>
									<button onClick={() => toggleDraggable(popupData.items[selectedMarker])} className="move" disabled={!isEditing}>
										<span>
											<FontAwesomeIcon icon={['fa', 'expand-arrows-alt']} />
										</span>
										{draggable ? t('buttonNames.confirm') : t('buttonNames.move')}
									</button>
									{draggable && (
										<button onClick={onCancel} className="move bg-warning" disabled={!isEditing}>
											<span>
												<FontAwesomeIcon icon={['fa', 'times']} />
											</span>
											{t('buttonNames.cancel')}
										</button>
									)}
								</Fragment>
							) : null}
							<button className="ticketing" disabled={!isEditing} onClick={onClickTicketing}>
								<span>
									<FontAwesomeIcon icon={['fa', 'calendar-alt']} />
								</span>
								{t('buttonNames.ticketing')}
							</button>
							{/* {typeof onInventariClick === 'function' ? (
							<button onClick={handleInventariClick} className="inventary" disabled={!isEditing}>
								<span>
									<FontAwesomeIcon icon={['fa', 'clipboard-check']} />
								</span>
								{t('buttonNames.inventari')}
							</button>
						) : null} */}
							{typeof handleInventaryClick === 'function' ? (
								<button onClick={handleInventaryClick} className="inventary" disabled={!isEditing}>
									<span>
										<FontAwesomeIcon icon={['fa', 'clipboard-check']} />
									</span>
									{t('buttonNames.inventari')}
								</button>
							) : null}
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	return <Popup className="MarkerPopup">{renderBody()}</Popup>;
};

export default MarkerPopup;
