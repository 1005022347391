import React from 'react';
import WithFormConfig from '../../hocs/WithFormConfig';
import CustomPlanificationsTemporalDistribution from "./CustomPlanificationsTemporalDistribution";


export default WithFormConfig(
	CustomPlanificationsTemporalDistribution,
	true,
	'planifications-temporal-distribution-aggregate'
);
