import React, { useEffect, useRef } from 'react';
import jsPDF from 'jspdf';

export const CustomSarReportGenerator = props => {
	const { data, hideReport } = props;
	const reportTemplateRef = useRef(null);
	const downloadReport = () => {
		const doc = new jsPDF({
			format: [1000, 700],
			unit: 'px',
			orientation: 'portrait',
			compress: true
		});

		doc.html(reportTemplateRef.current, {
			callback(doc) {
				window.open(doc.output('bloburi'));
				hideReport();
			}
		});
	};

	useEffect(() => downloadReport(), []);
	return <div style={{ opacity: 0 }}>
		<div ref={reportTemplateRef} style={{ width: 700 }}>
			<h4 style={{ background: '#4ed0f3', padding: 5 }}>Informe personal de totes les accions</h4>
			{
				data.map((item, index) => {
					return <div key={item.pk} style={{ background: index % 2 !== 0 ? '#e7e6e6' : '#ffffff' }}>
						<table style={{ width: 700 }}>
							<thead style={{ opacity: index === 0 ? 1 : 0 }}>
							<tr>
								<td style={{ textAlign: 'left' }}>FechaEntrada</td>
								<td style={{ textAlign: 'left' }}>Usuari</td>
								<td style={{ textAlign: 'left' }}>Acció</td>
								<td style={{ textAlign: 'left' }}>Resp. Predeterm</td>
								<td style={{ textAlign: 'left' }}>Horas</td>
								<td style={{ textAlign: 'left' }}>Departament</td>
								<td style={{ textAlign: 'left' }}>Certificat</td>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>{item.action_date}</td>
								<td>{item.person_name}</td>
								<td>{item.contact_type_name}</td>
								<td>{item.default_answer_name}</td>
								<td>{item.hours}</td>
								<td>{item.department_name}</td>
								<td>{item.certification_name}</td>
							</tr>
							</tbody>
						</table>
						<div style={{ fontSize: '0.7rem'}} dangerouslySetInnerHTML={{ __html: item.comments }} />
					</div>;
				})
			}
		</div>
	</div>;
};