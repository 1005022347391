// @doc https://react-chartjs-2.js.org/examples/
import React from 'react';
import PropTypes from 'prop-types';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { typeGraphsDefaultData } from './TypeGraphsData';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getGraphData = ({ xTitle, yTitle, title, xLabels, lineColor = 'rgb(255, 0, 0)', data, dataLabels }) => {
	let options = { ...typeGraphsDefaultData };
	let datasets = [];
	if( Array.isArray(data) ) {
		if( Array.isArray(data[0]) ) {
			for( let index = 0; index < data.length; ++index ) {
				let tmpDataset = {
					data: Array.isArray(data[index]) && data[index],
					backgroundColor: lineColor[index],
					borderColor: lineColor[index],
					borderWidth: 2,
					minBarLength: 0
				};
				if( Array.isArray(dataLabels) && dataLabels.length > 0 ) {
					tmpDataset["label"] = dataLabels[index];
				}
				datasets.push(tmpDataset);
			}
		} else {
			datasets.push({
				data: Array.isArray(data) && data,
				backgroundColor: lineColor,
				borderColor: lineColor,
				borderWidth: 2,
				minBarLength: 0
			});
		}
	}
	let graphData = {
		labels: xLabels,
		datasets: datasets
	};
	if (title)
		options['plugins'] = {
			...options['plugins'],
			legend: {
				display: !!(Array.isArray(data) && Array.isArray(data[0]) && Array.isArray(data[0]["dataLabels"])),
			},
			title: {
				display: true,
				text: title
			}
		};
	if (xTitle)
		options['scales'] = {
			...options['scales'],
			xAxes: {
				title: {
					display: true,
					text: xTitle
				}
			}
		};
	if (yTitle)
		options['scales'] = {
			...options['scales'],
			yAxes: {
				title: {
					display: true,
					text: yTitle
				},
				ticks: {
					stepSize: 1,
					beginAtZero: true
				}
			}
		};
	return { options, data: graphData };
};

const BarGraph = (props) => {
	const { className } = props;
	const { options, data } = getGraphData(props);

	return <div className={className}>{data && options && <Bar options={options} data={data} />}</div>;
};

BarGraph.propTypes = {
	className: PropTypes.string,
	xTitle: PropTypes.string,
	yTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	xLabels: PropTypes.array.isRequired,
	lineColor: PropTypes.string,
	data: PropTypes.array.isRequired,
	dataLabels: PropTypes.string
};

export default BarGraph;
