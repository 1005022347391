import axios from 'axios';
import { BASE_URL, MEDIA_BASE_URL, REACT_APP_LOGOUT_URL, REACT_APP_LOGIN_URL, LANG, WITH_LANGUAGES } from '../config';
import { clearLocalStorage, readLocalStorage, writeLocalStorage } from './localStorageManagement';
import { readSessionStorage, writeSessionStorage } from './sessionStorageManagement';

let refreshTime = null;

// Crear una instancia de Axios
const axiosInstance = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json;charset=utf-8'
	}
});
export const AxiosInterceptor = () => {
	// Interceptor para adjuntar el token a cada solicitud
	axiosInstance.interceptors.request.use(
		(config) => {
			const userInfo = JSON.parse(readLocalStorage('userInfo', '{}'));
			if (userInfo?.access) {
				config.headers.Authorization = `Bearer ${userInfo.access}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	// Interceptor para manejar respuestas y refrescar el token si es necesario
	axiosInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;

				try {
					const userInfo = JSON.parse(readLocalStorage('userInfo', '{}'));

					const { refresh } = userInfo;

					const { data } = await axios.post(`${BASE_URL}/token/refresh/`, { refresh });
					setUserData(data);
					// Si en algún momento se quiere solo refrescar el jwt access y no el refresh, activar la linea de abajo y comentar la de arriba. Así no refresca el token refresh también
					// setUserData({ access: data.access });
					originalRequest.headers.Authorization = `Bearer ${data.access}`;
					return axios(originalRequest);
				} catch (err) {
					clearLocalStorage();
					window.location = '/';
					return;
				}
			}
			return Promise.reject(error);
		}
	);
};

export const deleteUserInfo = async (returnToHome) => {
	clearLocalStorage();
	if (returnToHome) {
		window.location = '/';
	}
};

export const getUserInfo = () => readLocalStorage('userInfo', '{}');

export const isThereUserInformation = () => !!readLocalStorage('userInfo');

export const setUserData = (userInfo) => {
	const currentInfo = JSON.parse(getUserInfo());
	writeLocalStorage('userInfo', JSON.stringify({ ...currentInfo, ...userInfo }));
	window.dispatchEvent(new Event('userInfoEvent'));
};

export const loginMethod = async ({ username, password }) => {
	const params = {
		method: 'POST',
		body: JSON.stringify({ username, password, erp: 1 }),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	};
	try {
		const res = await fetch(`${BASE_URL}/token/`, params);
		const response = await res.json();
		if (!response.access) return null;
		const res2 = await fetch(`${BASE_URL}${REACT_APP_LOGIN_URL}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${response.access}`,
				'Content-Type': 'application/json;charset=utf-8'
			}
		});
		const { user_full_name, login } = await res2.json();
		if (login) {
			setUserData({ ...response, userName: user_full_name, login });
			return { ...response, userName: user_full_name, login };
		} else {
			return { login };
		}
	} catch (e) {
		throw new Error(e);
	}
};

export const authenticatedFetcher = async ({
	path,
	urlParams = {},
	body = {},
	method = 'GET',
	file = false,
	noRefresh = false,
	abortSignal = null,
	langReq = false
}) => {
	try {
		const userInfo = JSON.parse(getUserInfo());
		const { access: token = '', refresh = '' } = userInfo || {};
		let localLang = WITH_LANGUAGES ? readSessionStorage('i18nextLng') : LANG;
		if (!localLang && !langReq && WITH_LANGUAGES) {
			const { data: langs } = await axiosInstance.get('/config/languages/');
			if (langs && Array.isArray(langs)) {
				writeSessionStorage('i18nextLng', langs[0].pk);
				localLang = langs[0].pk;
			} else {
				writeSessionStorage('i18nextLng', LANG);
				localLang = LANG;
			}
		}
		clearTimeout(refreshTime);
		if (!token) throw new Error('TOKEN ERROR');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json;charset=utf-8'
			},
			method,
			signal: abortSignal
		};

		if (file) {
			delete config.headers['Content-Type'];
			config.data = body;
		} else {
			if (method === 'POST' || method === 'PUT' || method === 'PATCH')
				config.data = JSON.stringify(body);
		}
		const reqParams = typeof urlParams === 'object'
			? { lang: localLang, ...urlParams }
			: { lang: localLang };
		const url = new URL(`${BASE_URL}${path}`);
		Object.keys(reqParams).forEach((key) =>
			reqParams[key] && url.searchParams.append(key, reqParams[key]));
		config.url = url;

		const response = await axiosInstance(config);

		if (response.status === 204) {
			return { error: 'no-content' };
		}

		if (response.data && response.data.detail === 'Login failed.') {
			window.localStorage.removeItem('user_token');
			throw new Error('Login failed.');
		}

		if ([200, 201, 206].includes(response.status)) {
			return file ? { ...response.data, mediaBaseUrl: MEDIA_BASE_URL } : response.data;
		}

		throw new Error('Fetch error.');
	} catch (e) {
		const response = e.response ? e.response : e;
		if (response.status === 403) {
			window.location = '/';
			return { error: 'No autorizado' };
		}

		if (response.status === 400 && response.data && response.data.email) {
			throw new Error(response.data.email[0]);
		}

		if (response.status === 400 && response.data) {
			return {
				error: response.data.response ? response.data.response[0] : 'Algo salio mal',
				error_data: response.data,
				status: response.status
			};
		}

		return { error: 'Algo salio mal' };
	}
};

export default authenticatedFetcher;
