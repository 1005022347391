import React, { useState } from 'react';
import { SarMemeberSearchForm } from './SarMemeberSearchForm';
import { SarDataRecoveryForm } from './SarDataRecoveryForm';
import { SarActionsTab } from './SarActionsTab';
import authenticatedFetcher from '../../services/fetcher';
import { CustomSarReportGenerator } from './CustomSarReportGenerator';

const CustomSar = () => {
	const [sarSearchResult, setSarSearchResult] = useState([]);
	const [selectedUser, setSelectedUser] = useState();
	const [report, setReport] = useState();

	const fetchReport = () => {
		authenticatedFetcher({
			path: '/sar/report/',
			urlParams: {
				person: selectedUser?.person_data.pk
			}
		}).then(response => setReport(response));
	};
	return (
		<div style={{ fontSize: '11px' }} className="my-4">

			<div className="col mb-4">
				<div className="row">
					{/* <!-- Sección Buscador de Miembros Certificados con Seguimiento Abierto --> */}
					<div className="card col-5 mr-2">
						<div style={{ backgroundColor: '#ddd819', marginInline: -20 }}
							 className="card-header text-white">
							<h5>Buscador de Miembros Certificados con Seguimiento Abierto</h5>
						</div>
						<div className="card-body">
							<SarMemeberSearchForm setSearchResult={setSarSearchResult} />

							<div style={{ maxHeight: '250px' }} className="table-responsive mt-3">
								<table className="table table-bordered">
									<thead className="thead-light">
									<tr>
										<th>Revisión</th>
										<th>Apellido1</th>
										<th>Apellido2</th>
										<th>Nombre</th>
										<th>Usuario</th>
									</tr>
									</thead>
									<tbody style={{ overflowY: 'auto' }}>
									{
										sarSearchResult.map(item => {
											return <tr key={item.pk}>
												<td>{item.control_date}</td>
												<td>{item.person_data.last_name}</td>
												<td>{item.person_data.second_last_name}</td>
												<td>{item.person_data.first_name}</td>
												<td>
													<button
														type="button"
														className="btn"
														style={{
															fontSize: 11,
															textDecoration: 'underline',
															color: 'blue'
														}}
														onClick={() => setSelectedUser(item)}>{item.action_person_name}</button>
												</td>
											</tr>;
										})
									}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{/* <!-- Sección Información Personal --> */}
					<div className="card col ml-2">
						<div
							style={{
								backgroundColor: '#1f487c',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginInline: -20
							}}
							className="card-header bg-primary text-white">
							<h5>Información Personal</h5>
							{/*<button style={{ cursor: 'pointer' }} type="button" className="btn btn-secondary ">
								&#128209;
							</button>*/}
						</div>
						<div className="card-body">
							<SarDataRecoveryForm userPk={selectedUser?.person_data.pk} />
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Sección Introducción Acciones Seguimiento Recertificación --> */}
			<div className="card mb-4">
				<div
					style={{
						backgroundColor: '#1f487c',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
					className="card-header text-white">
					<h5>Introducción Acciones Seguimiento Recertificación</h5>
					{
						selectedUser && <button
							style={{ backgroundColor: '#dde6fa', cursor: 'pointer' }}
							type="button"
							className="btn btn-secondary"
							onClick={fetchReport}>
							Informe
						</button>
					}
				</div>
				<SarActionsTab userPk={selectedUser?.person_data.pk} />
			</div>
			{
				report && <CustomSarReportGenerator data={report} hideReport={() => setReport(undefined)} />
			}
		</div>
	);
};

export default CustomSar;
