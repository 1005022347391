import React, { useEffect, useState } from 'react';
import authenticatedFetcher from '../../services/fetcher';
import { toastSuccess } from '../../utils/ToastConfig';

export const SarMemeberSearchForm = props => {
	const { setSearchResult } = props;
	const [defaultAnswers, setDefaultAnswers] = useState([]);

	const [selectedDefaultAnswer, setSelectedDefaultAnswer] = useState();
	const [memberNumber, setMemberNumber] = useState();
	const [identityNumber, setIdentityNumber] = useState();
	const [fromDate, setFromDate] = useState();
	const [untilDate, setUntilDate] = useState();

	const handleSelectedDefaultAnswer = (event) => setSelectedDefaultAnswer(event.target.value);
	const handleMemberNumberChange = (event) => setMemberNumber(event.target.value);
	const handleIdentityNumberChange = (event) => setIdentityNumber(event.target.value);
	const handleFromDateChange = (event) => setFromDate(event.target.value);
	const handleUntilDateChange = (event) => setUntilDate(event.target.value);

	const getDefaultAnswers = () => {
		authenticatedFetcher({
			path: '/sar/default-answers/',
			urlParams: {
				nitems: 500
			}
		}).then(response => setDefaultAnswers(response));
	};

	const exportData = (e) => {
		e.preventDefault();
		authenticatedFetcher({
			path: '/sar/export/',
			method: 'POST'
		}).then(response => toastSuccess(
			response.response,
			{ autoClose: false },
			true
		));
	};

	const onSubmit = (e) => {
		e.preventDefault();
		authenticatedFetcher({
			path: '/sar/search/',
			method: 'POST',
			body: {
				default_answer: selectedDefaultAnswer,
				code: memberNumber,
				identity_document: identityNumber,
				date_init: fromDate,
				date_finish: untilDate
			}
		}).then(response => setSearchResult(response));
	};

	useEffect(() => getDefaultAnswers(), []);
	return <form onSubmit={onSubmit}>
		<div className="form-row">
			<div className="col-md-4 form-group">
				<label htmlFor="respostaAutomatica">Respuesta automática:</label>
				<select className="form-control BasicInput" id="respostaAutomatica" value={selectedDefaultAnswer}
						onChange={handleSelectedDefaultAnswer}>
					<option value=""></option>
					{
						defaultAnswers.map(item => {
							return <option key={item.pk} value={item.pk}>{item.name}</option>;
						})
					}
				</select>
			</div>
			<div className="col-md-4 form-group">
				<label htmlFor="numeroMembre">Número de miembro:</label>
				<input type="text" className="form-control BasicInput" id="numeroMembre" value={memberNumber}
					   onChange={handleMemberNumberChange} />
			</div>
			<div className="col-md-4 form-group">
				<label htmlFor="nif2">NIF:</label>
				<input type="text" className="form-control BasicInput" id="nif2"
					   value={identityNumber} onChange={handleIdentityNumberChange} />
			</div>
		</div>
		<div className="form-row">
			<div className="col-md-6 form-group" style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
				<label htmlFor="fromDate">Fecha desde:</label>
				<input type="date" className="form-control BasicInput" id="fromDate"
					   value={fromDate} onChange={handleFromDateChange} max={untilDate} />
			</div>
			<div className="col-md-6 form-group" style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
				<label htmlFor="untilDate">Hasta</label>
				<input type="date" className="form-control BasicInput" id="untilDate"
					   value={untilDate} onChange={handleUntilDateChange} min={fromDate} />
			</div>
		</div>
		<div className="text-right">
			<button style={{ cursor: 'pointer' }} type="button" onClick={exportData}
					className="btn btn-secondary mr-1">
				Exportar Prórrogas vigentes &#128215;
			</button>
			<button style={{ cursor: 'pointer' }} type="submit"
					className="btn btn-secondary mr-1">
				Buscar &#x1F4DA;
			</button>
		</div>
	</form>;

};