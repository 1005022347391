import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DynamicFormLayout } from '../../components/DynamicForm';
import WithFormConfig from '../../hocs/WithFormConfig';
import { getMethods, objectToUrlParams } from '../../services/commonServices';
import authenticatedFetcher from '../../services/fetcher';
import { useTranslation } from 'react-i18next';
import Message from '../../components/Message';
import LeavePageBlocker from '../../components/LeavePageBlocker'
import { Button, Card } from 'reactstrap';
import { toastSuccess, toastError } from "../../utils/ToastConfig";
import htmlParse from "html-react-parser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const CustomPlanificationsTreasuryForecast = (props) => {
    const [actions = {}] = useState(getMethods(props.config.actions));
    const [t] = useTranslation('app');
    const [isMounted, setIsMounted] = useState(false);
    const [showErrorMessage, handleErrorMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [refreshCount, setRefreshCount] = useState(0);
    const [urlBody, setUrlBody] = useState();
    const history = useHistory();
    const [alertWhenExit, setAlertWhenExit] = useState(false);
    const { read, write, export: exportMethod } = actions || {};
    const [searchParams, setSearchParams] = useState({});
	const [totalsContracts, setTotalsContracts] = useState([]);
	const [totals, setTotals] = useState([0, 0, 0, 0, 0, 0]);
    const [rowsData, setRowsData] = useState({});
	const [canEdit, setCanEdit] = useState(true);
	const [monthLabels, setMonthLabels] = useState([]);
    const [showTable, setShowTable] = useState(false);
	const CLASS_NAME_ROW_GREEN = 'bg-green-300';
	const CLASS_NAME_ROW_YELLOW = 'bg-yellow-150';
	const MONTH_LABELS = [
		"gen", "feb", "mar", "abr", "mai", "jun", "jul", "ago", "sep", "oct", "nov", "des"
	];
	const CONCEPTS = {
		"prod": {
			"label": "Producció",
			"variables": ["Produccion_Mes","Produccion_Mes2","Produccion_Mes3","Produccion_Mes4","Produccion_Mes5","Produccion_Mes6"],
			"totals": [0, 0, 0, 0, 0, 0],
			"totals_cobros": [0, 0, 0, 0, 0, 0]  // no se usa
		},
		"cert": {
			"label": "Certificació",
			"variables": ["Certificacion_Mes","Certificacion_Mes2","Certificacion_Mes3","Certificacion_Mes4","Certificacion_Mes5","Certificacion_Mes6"],
			"totals": [0, 0, 0, 0, 0, 0],
			"totals_cobros": [0, 0, 0, 0, 0, 0]    // no se usa
		},
		"cob5": {
			"label": "<strong>Cobrament 5</strong>",
			"variables": ["Cobro1_Mes","Cobro1_Mes2","Cobro1_Mes3","Cobro1_Mes4","Cobro1_Mes5","Cobro1_Mes6"],
			"totals": [0, 0, 0, 0, 0, 0],
			"totals_cobros": [0, 0, 0, 0, 0, 0]
		},
		"cob20": {
			"label": "<strong>Cobrament 20</strong>",
			"variables": ["Cobro2_Mes","Cobro2_Mes2","Cobro2_Mes3","Cobro2_Mes4","Cobro2_Mes5","Cobro2_Mes6"],
			"totals": [0, 0, 0, 0, 0, 0],
			"totals_cobros": [0, 0, 0, 0, 0, 0]
		},
		"cob30": {
			"label": "<strong>Cobrament 30</strong>",
			"variables": ["Cobro3_Mes","Cobro3_Mes2","Cobro3_Mes3","Cobro3_Mes4","Cobro3_Mes5","Cobro3_Mes6"],
			"totals": [0, 0, 0, 0, 0, 0],
			"totals_cobros": [0, 0, 0, 0, 0, 0]
		}
	};
	const [conceptsData, setConceptsData] = useState(CONCEPTS);
	const [showContractTables, setShowContractTables] = useState(false);


    useEffect(() => {
        if (!isMounted && history.location.search) {
            const urlParams = Object.fromEntries(new URLSearchParams(history.location.search));
            if (urlParams.dosearch) {
                handleSearch(urlParams, false);
            }
            setIsMounted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search]);

	const initializeTotalsData = (dataTable) => {
        Object.keys(CONCEPTS).map((key) => {
			CONCEPTS[key].totals = [0, 0, 0, 0, 0, 0];
			CONCEPTS[key].totals_cobros = [0, 0, 0, 0, 0, 0];
		});

		let contractsTotals = [];
		Object.keys(dataTable).map((key) => {
			contractsTotals.push({
				"totals_months": [0, 0, 0, 0, 0, 0],
				"totals": [0, 0, 0, 0, 0, 0]
			});
		});

		return contractsTotals;
    };

	const initializeMonthsLabels = (year, period) => {
		year = parseInt(year.toString().slice(-2));
		period = parseInt(period);
        let newMonthsLabels = [];
		let index = period-1;
		if( index < 0 ) {
			index = 11;
			year -= 1;
		}
		for( let i = 0; i < 6; ++i ) {
			if( index > 11 ) {
				index = 0;
				year += 1;
			}
			newMonthsLabels.push(MONTH_LABELS[index]+'-'+year);
			index += 1;
		}
		setMonthLabels(newMonthsLabels);
		setRefreshCount((prev) => prev + 1);
		return true;
    };

    const calculateTotals = (items) => {
		let dataTable = items ? items : rowsData;
		let totals = [0, 0, 0, 0, 0, 0];
		if (dataTable && Object.keys(dataTable).length > 0) {
			let contractsTotals = initializeTotalsData(dataTable);

			Object.keys(dataTable).map((key) => {
				let count = 0;
				Object.keys(CONCEPTS).map((keyConcept) => {
					Object.keys(CONCEPTS[keyConcept].variables).map((keyVariable) => {
						let variableName = CONCEPTS[keyConcept].variables[keyVariable];
						let variableValue = Number(dataTable[key][variableName]);
						//console.log(variableName + ' ==> ' + dataTable[key][variableName]);
						CONCEPTS[keyConcept].totals[keyVariable] += variableValue;
						if( count > 1 ) {
							contractsTotals[key].totals_months[keyVariable] += variableValue;
							CONCEPTS[keyConcept].totals_cobros[keyVariable] += variableValue;
							totals[keyVariable] += variableValue;
						}
					});
					count += 1;
				});

			});

			setConceptsData(CONCEPTS);
			setTotalsContracts(contractsTotals);
			setTotals(totals);
			setRefreshCount((prev) => prev + 1);
			// console.log(CONCEPTS);

			return true;
		}

		return null;
	};

    const handleSearch = (data, saveParams = true) => {
		if (!read) return;
		const body = Object.entries(data).reduce((acc, [key, value]) => {
			if (typeof value === 'boolean' || value) return { ...acc, [key]: value };
			return acc;
		}, {});
		setSearchParams(body);
		setUrlBody(body);
		const searchParams = objectToUrlParams(body);
		if (saveParams)
			history.push({ search: searchParams });
		//console.log(body);
		setShowTable(false);
		setShowContractTables(body && 'department' in body);

		// GET GENERIC DATA
		authenticatedFetcher({
			method: "POST",
			path: "/planners/treasury-forecast/search/",
			body
		})
			.then((res) => {
				if ('error' in res) {
					setShowTable(false);
					setErrorMessage(res['error']);
					return handleErrorMessage(true);
				}
				initializeMonthsLabels(body['year'], body['period']);
				setRowsData(res['items']);
				setCanEdit(res['can_edit']);
				calculateTotals(res['items']);
				setRefreshCount((prev) => prev + 1);
				handleErrorMessage(false);
				setShowTable(true);
			})
			.catch(() => {
				setShowTable(false);
				handleErrorMessage(true);
			});
	};

	const submitData = () => {
		if (!write) return;
        authenticatedFetcher({
            method: "POST",
            path: "/planners/treasury-forecast/save-data/",
            body: rowsData
        })
            .then((res) => {
                if ('error' in res) {
                    toastError(
                        htmlParse(res.error),
                        { autoClose: false }
                    );
                } else {
                    toastSuccess(
                        res.response,
                        { autoClose: 'fixed_message' in res ? !res.fixed_message : true },
                        true
                    );
                }

                setAlertWhenExit(false);
                handleErrorMessage(false);
                handleSearch(searchParams);
            })
            .catch(() => handleErrorMessage(true));
	};

	const convertToDecimal = (value) => {
		return value === '' ? parseFloat("0.00") : parseFloat(value);
	};

    const parseDecimal = (value, suffix = "", numDecimals = 2) => {
		let default_value = "0,00";
		let parsed_value = (value ? Intl.NumberFormat(
			"es-ES",
			{ maximumFractionDigits: numDecimals, minimumFractionDigits: numDecimals, useGrouping: true }
		).format(value) + suffix : default_value);

		if( parsed_value === "-0,00" ) {
			parsed_value = default_value;
		}
		return parsed_value;
	};

	const updateData = (index, event, recalculate) => {
		let fieldName = event.target.name;
		let fieldValue = event.target.value;
		rowsData[index][fieldName] = fieldValue;
		setAlertWhenExit(true);
		setRowsData(rowsData);
		if( recalculate) {
			calculateTotals(rowsData);
		}
		setRefreshCount((prev) => prev + 1);
	};

	const renderTableTotalsHeader = () => {
		return (
			<Fragment>
				<thead>
					<tr>
						<td className="text-center td-blue-white"  width="52%" colSpan="5">
							<strong>TOTALS PREVISTOS</strong>
						</td>
						{monthLabels && Object.keys(monthLabels).map((key) => {
							return (
								<Fragment>
									<td className="text-center td-green-header" width="8%">
										{monthLabels[key]}
									</td>
								</Fragment>
							);
						})}
					</tr>
				</thead>
			</Fragment>
		);
	};

	const renderTableContractsHeader = () => {
		return (
			<Fragment>
				<thead>
					<tr>
						<td className="text-center td-green-header"  width="5%">
							Nº SIE
						</td>
						<td className="text-center td-green-header" width="17%">
							NOM
						</td>
						<td className="text-center td-green-header" width="5%">
							PAGA DIES
						</td>
						<td className="text-center td-green-header" width="15%">
							CLIENT
						</td>
						<td className="text-center td-green-header" width="10%"></td>
						{monthLabels && Object.keys(monthLabels).map((key) => {
							return (
								<Fragment>
									<td className="text-center td-green-header" width="8%">
										{monthLabels[key]}
									</td>
								</Fragment>
							);
						})}
					</tr>
				</thead>
			</Fragment>
		);
	};

	const renderTableTotalsRows = () => {
		return (
			<Fragment>
				<tbody>
					{conceptsData && Object.keys(conceptsData).map((key) => {
						return (
							<Fragment>
								<tr>
									<td className="text-center bg-white" width="5%"></td>
									<td className="text-center bg-white" width="17%"></td>
									<td className="text-center bg-white" width="5%"></td>
									<td className="text-center bg-white" width="15%"></td>
									<td className="text-center bg-white" width="10%">
										{htmlParse(conceptsData[key]["label"])}
									</td>
									<td className="text-center td-bg-blue-white" width="8%">
										<span>{parseDecimal(conceptsData[key]["totals"][0])}</span>
									</td>
									<td className="text-center td-bg-blue-white" width="8%">
										<span>{parseDecimal(conceptsData[key]["totals"][1])}</span>
									</td>
									<td className="text-center td-bg-blue-white" width="8%">
										<span>{parseDecimal(conceptsData[key]["totals"][2])}</span>
									</td>
									<td className="text-center td-bg-blue-white" width="8%">
										<span>{parseDecimal(conceptsData[key]["totals"][3])}</span>
									</td>
									<td className="text-center td-bg-blue-white" width="8%">
										<span>{parseDecimal(conceptsData[key]["totals"][4])}</span>
									</td>
									<td className="text-center td-bg-blue-white" width="8%">
										<span>{parseDecimal(conceptsData[key]["totals"][5])}</span>
									</td>
								</tr>
							</Fragment>
						);
					})}

				</tbody>
			</Fragment>
		);
	};

	const renderTableContractsRowTotals = (index) => {
		let totalsContract = totalsContracts[index].totals_months;
		return (
			<Fragment>
				<tr>
					<td className="text-center td-blue_gray" width="5%"></td>
					<td className="text-center td-blue_gray"  width="17%"></td>
					<td className="text-center td-blue_gray" width="5%"></td>
					<td className="text-center td-blue_gray" width="15%"></td>
					<td className="text-center td-blue_gray" width="10%">
						TOTAL COBRAMENTS
					</td>

					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totalsContract[0])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totalsContract[1])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totalsContract[2])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totalsContract[3])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totalsContract[4])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totalsContract[5])}</span>
					</td>
				</tr>
			</Fragment>
		);
	};

	const renderTableContractsRows = (index, item) => {
		let count = 0;
		return (
			<Fragment>
					{conceptsData && Object.keys(conceptsData).map((key) => {
						count += 1;
						return (
							<Fragment>
								<tr>
									<td className="text-center bg-white" width="5%">
										{count === 1 && (
											<strong>{item?.Contrato}</strong>
										)}
									</td>
									<td className="text-left bg-white"  width="17%">
										{count === 1 && (
											<strong>{item?.Contrato_nombre}</strong>
										)}
									</td>
									<td className={count === 1 ? 'text-center '+CLASS_NAME_ROW_YELLOW : 'text-center bg-white'} width="5%">
										{count === 1 && (
											<Fragment>
											{canEdit ? (
												<input
													className="form-control input-text-wrapper input-planning-small text-center"
													type="number"
													name="Pago_Dias"
													defaultValue={item?.Pago_Dias}
													onChange={event => updateData(index, event, false)}
												/>
											) : (
												<span className="status-from-buttons-in-table-item-list">
													{item?.Pago_Dias}
												</span>
											)}
											</Fragment>
										)}
									</td>
									<td className={count === 1 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center bg-white'} width="15%">
										{count === 1 && (
											<Fragment>
											{canEdit ? (
												<input
													className="form-control input-text-wrapper input-planning-small"
													type="text"
													name="Cliente"
													defaultValue={item?.Cliente}
													onChange={event => updateData(index, event, false)}
												/>
											) : (
												<span className="status-from-buttons-in-table-item-list">
													{item?.Cliente}
												</span>
											)}
											</Fragment>

										)}
									</td>
									<td className="text-center bg-white" width="10%">
										{htmlParse(conceptsData[key]["label"])}
									</td>

									<td className={count < 3 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center '+CLASS_NAME_ROW_YELLOW} width="8%">
										{canEdit ? (
											<input
												className="form-control input-text-wrapper input-planning-small text-center"
												type="number"
												name={conceptsData[key]["variables"][0]}
												defaultValue={eval('item?.'+conceptsData[key]["variables"][0])}
												onChange={event => updateData(index, event, true)}
											/>
										) : (
											<span className="status-from-buttons-in-table-item-list">
												{parseDecimal(eval('item?.'+conceptsData[key]["variables"][0]))}
											</span>
										)}
									</td>
									<td className={count < 3 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center '+CLASS_NAME_ROW_YELLOW} width="8%">
										{canEdit ? (
											<input
												className="form-control input-text-wrapper input-planning-small text-center"
												type="number"
												name={conceptsData[key]["variables"][1]}
												defaultValue={eval('item?.'+conceptsData[key]["variables"][1])}
												onChange={event => updateData(index, event, true)}
											/>
										) : (
											<span className="status-from-buttons-in-table-item-list">
												{parseDecimal(eval('item?.'+conceptsData[key]["variables"][1]))}
											</span>
										)}
									</td>
									<td className={count < 3 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center '+CLASS_NAME_ROW_YELLOW} width="8%">
										{canEdit ? (
											<input
												className="form-control input-text-wrapper input-planning-small text-center"
												type="number"
												name={conceptsData[key]["variables"][2]}
												defaultValue={eval('item?.'+conceptsData[key]["variables"][2])}
												onChange={event => updateData(index, event, true)}
											/>
										) : (
											<span className="status-from-buttons-in-table-item-list">
												{parseDecimal(eval('item?.'+conceptsData[key]["variables"][2]))}
											</span>
										)}
									</td>
									<td className={count < 3 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center '+CLASS_NAME_ROW_YELLOW} width="8%">
										{canEdit ? (
											<input
												className="form-control input-text-wrapper input-planning-small text-center"
												type="number"
												name={conceptsData[key]["variables"][3]}
												defaultValue={eval('item?.'+conceptsData[key]["variables"][3])}
												onChange={event => updateData(index, event, true)}
											/>
										) : (
											<span className="status-from-buttons-in-table-item-list">
												{parseDecimal(eval('item?.'+conceptsData[key]["variables"][3]))}
											</span>
										)}
									</td>
									<td className={count < 3 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center '+CLASS_NAME_ROW_YELLOW} width="8%">
										{canEdit ? (
											<input
												className="form-control input-text-wrapper input-planning-small text-center"
												type="number"
												name={conceptsData[key]["variables"][4]}
												defaultValue={eval('item?.'+conceptsData[key]["variables"][4])}
												onChange={event => updateData(index, event, true)}
											/>
										) : (
											<span className="status-from-buttons-in-table-item-list">
												{parseDecimal(eval('item?.'+conceptsData[key]["variables"][4]))}
											</span>
										)}
									</td>
									<td className={count < 3 ? 'text-center '+CLASS_NAME_ROW_GREEN : 'text-center '+CLASS_NAME_ROW_YELLOW} width="8%">
										{canEdit ? (
											<input
												className="form-control input-text-wrapper input-planning-small text-center"
												type="number"
												name={conceptsData[key]["variables"][5]}
												defaultValue={eval('item?.'+conceptsData[key]["variables"][5])}
												onChange={event => updateData(index, event, true)}
											/>
										) : (
											<span className="status-from-buttons-in-table-item-list">
												{parseDecimal(eval('item?.'+conceptsData[key]["variables"][5]))}
											</span>
										)}
									</td>
								</tr>
							</Fragment>
						);
					})}

			</Fragment>
		);
	};

	const renderTableTotalsRowTotals = () => {
		return (
			<Fragment>
				<tr>
					<td className="text-center td-blue_gray" width="5%"></td>
					<td className="text-center td-blue_gray"  width="17%"></td>
					<td className="text-center td-blue_gray" width="5%"></td>
					<td className="text-center td-blue_gray" width="15%"></td>
					<td className="text-center td-blue_gray" width="10%">
						TOTAL COBRAMENTS
					</td>

					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totals[0])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totals[1])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totals[2])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totals[3])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totals[4])}</span>
					</td>
					<td className="text-center td-blue_gray" width="8%">
						<span>{parseDecimal(totals[5])}</span>
					</td>
				</tr>
			</Fragment>
		);
	};

    const renderTableTotals = () => {
		return (
			<Fragment>
				<div className="card-box listado-personas card planification-module mt-4 mb-3">
					<div className="table-responsive">
						<table className="mb-0 table table-hover table-bordered">
							{renderTableTotalsHeader()}
							{renderTableTotalsRows()}
							{renderTableTotalsRowTotals()}
					   </table>
					</div>
				</div>
			</Fragment>
		);
	};

	const renderTableContract = (index, item) => {
		console.log('*********');
		console.log(searchParams);
		return (
			<Fragment>
				<div className="card-box listado-personas card planification-module mt-3">
					<div className="table-responsive">
						<table className="mb-0 table table-hover table-bordered">
							{index === 0 && renderTableContractsHeader()}
							<tbody>
								{renderTableContractsRows(index, item)}
								{renderTableContractsRowTotals(index)}
							</tbody>
					   	</table>
					</div>
				</div>
			</Fragment>
		);
	};

	const renderTableContracts = () => {
		return (
			<Fragment>
				{showContractTables && rowsData && Object.keys(rowsData).map((index) => {
					return renderTableContract(parseInt(index), rowsData[index]);
				})}
				{showContractTables && canEdit && (
					<Fragment>
						<div style={{ "float": "right", textAlign: "right", "width": "51%", marginTop: "1rem" }}>
							<Button size="submitButton btn btn-secondary btn-planning"
								style={{ "border": "1px solid", width: "150px" }}
								onClick={() => submitData()}>
								{t('DESAR')}
							</Button>
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	};

    return (
		<Fragment>
            <LeavePageBlocker when={alertWhenExit} />
			<div className="planification-module">
				<DynamicFormLayout
					config={props.config}
					onSubmit={handleSearch}
					withoutButtonsBar
					withoutRelateds
					filterMode
					isSearcher
					tabProcessingOptions={{
						loadDefaultData: true,
					}}
					inputsAlign={
						{
							labelColumn: { sm: 6, md: 5, lg: 4 },
							inputColumn: { sm: 6, md: 7, lg: 8 }
						}
					}
				/>

				{showTable && !showErrorMessage ? (
					<Fragment>
						{renderTableTotals()}
						{renderTableContracts()}
					</Fragment>

				) : (
					showErrorMessage && (
						<Card className="mt-4 p-2">
							<Message type="error" className="m-0">
								{errorMessage}
							</Message>
						</Card>
					)
				)}
			</div>
		</Fragment>
	);



};

CustomPlanificationsTreasuryForecast.propTypes = {};

export default WithFormConfig(
    CustomPlanificationsTreasuryForecast,
    true,
    'planifications-treasury-forecast-searcher',
    { urlParametersFirst: true }
);
