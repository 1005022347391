import { formatQuantity } from "../../../../services/commonServices";

const tooltipAndLabelFormat = { precision: 2, decimal: ',', thousand: '.' };
const formatTooltipAndLabel = (labelUnit) => formatQuantity(labelUnit, tooltipAndLabelFormat);

const typeGraphsDefaultData = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: ''
        },
        tooltip: {
            //docs: https://www.chartjs.org/docs/3.9.1/configuration/tooltip.html#tooltip-callbacks
            callbacks: {
                label: function (context) {
                    let label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null || context.parsed.x !== null) {
                        label += formatTooltipAndLabel(context.parsed.y || context.parsed.x)
                    }
                    return label;
                }
            }
        }
    },
    tension: '0.3',
    scales: {
        y: {
            ticks: {
                // docs: https://www.chartjs.org/docs/latest/samples/scale-options/ticks.html
                callback: function (val) {
                    return formatTooltipAndLabel(val);
                }
            }
        }
    }
}

export {
    typeGraphsDefaultData
}