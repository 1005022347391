import PropTypes from 'prop-types';

const inputTypesMap = {
	CHECKBOX: 'checkbox',
	DATE: 'date',
	FILE: 'file',
	RADIO: 'radio',
	PASSWORD: 'password',
	SELECT: 'select',
	TEXT: 'text',
	INTEGER: 'integer',
	BOOLEAN: 'boolean',
	COLOR: 'color',
	SELECT_ASYNC: 'select-async',
	TEXTAREA: 'textarea',
	TEXTAREA_RICH: 'textarea-rich',
	TIME: 'time',
	DATETIME: 'datetime',
	CUSTOM: 'custom',
	MULTISELECT: 'multiselect',
	MULTISELECT_VIEWSELECT: 'multiselect-viewselect',
	MULTISELECT_ASYNC: 'multiselect-async',
	FILTERTYPE: 'filterType',
	HIDDEN: 'hidden',
	GALLERY: 'gallery',
	GALLERY_TELEGESTIO: 'galleryTelegestio',
	STATIC: 'static',
	IFRAME: 'iframe',
	COORDINATES: 'coordinates',
	GRAPHS: 'graphs',
	MULTICHECKBOX: 'multicheckbox',
	MULTISELECT_INLINE: 'multiselect-inline',
	UNKNOW: 'unknow',
	EMPTY: 'empty'
};

const inputTypes = Object.values(inputTypesMap);

const inputTypeType = PropTypes.oneOf(inputTypes);

const inputValuesType = PropTypes.oneOfType([
	PropTypes.number,
	PropTypes.string,
	PropTypes.bool,
	PropTypes.array,
	// Input file
	PropTypes.shape({
		lastModified: PropTypes.number,
		lastModifiedDate: PropTypes.instanceOf(Date),
		name: PropTypes.string,
		size: PropTypes.number,
		type: PropTypes.string
	})
]);

const inputOptionsType = PropTypes.arrayOf(
	PropTypes.shape({
		text: PropTypes.string,
		value: inputValuesType
	})
);

const inputType = {
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	editable: PropTypes.bool,
	required: PropTypes.bool,
	value: inputValuesType,
	type: inputTypeType,
	items: inputOptionsType,
	divClassName: PropTypes.string,
	options: PropTypes.shape({
		key: PropTypes.string,
		endpoint: PropTypes.string,
		params: PropTypes.object
	})
};

const Types = {
	inputTypeType,
	inputValuesType,
	inputOptionsType,
	inputType
};

export { Types, inputTypesMap, inputTypes };
