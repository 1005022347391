import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import translations system
import './i18n';
import { AxiosInterceptor } from './services/fetcher';

AxiosInterceptor()
ReactDOM.render(<App />, document.getElementById('root'));
