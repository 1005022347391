import React, { useEffect, useState, useRef } from 'react';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Button, Form } from 'react-bootstrap';
import ckUploader from '../../services/ckUploader';
import authenticatedFetcher from '../../services/fetcher';
import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import TypeFormInputBoolean from '../../components/DynamicForm/TypeForm/TypeFormInputBoolean';
import { Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function CustomModalCreateMassiveEmail(props) {
	const { toggle, open } = props;
	const editorRef = useRef();
	const [currentValue, setCurrentValue] = useState('');
	const [formData, setFormData] = useState({});
	const [mailOptions, setMailOptions] = useState([]);
	const [levelsOptions, setLevelsOptions] = useState([]);
	const [groupsOptions, setGroupsOptions] = useState([]);
	const [templateTypesOptions, setTemplateTypesOptions] = useState([]);
	const [filterTypesOptions, setFilterTypesOptions] = useState([]);
	const [t] = useTranslation('app');

	useEffect(() => {
		authenticatedFetcher({ path: '/emails/massive/templates/' }).then(
			(res) => Array.isArray(res) && setMailOptions(res)
		);
		authenticatedFetcher({ path: '/emails/massive/persons-levels/' }).then(
			(res) => Array.isArray(res) && setLevelsOptions(res)
		);
		authenticatedFetcher({ path: '/exams/recertification-groups/actuals-and-last-year/' }).then(
			(res) => Array.isArray(res) && setGroupsOptions(res)
		);
		authenticatedFetcher({ path: '/emails/massive/template-types/' }).then(
			(res) => Array.isArray(res) && setTemplateTypesOptions(res)
		);
		authenticatedFetcher({ path: '/emails/massive/filters/' }).then(
			(res) => Array.isArray(res) && setFilterTypesOptions(res)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnInit = (editor) => {
		editorRef.current = editor;
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return new ckUploader(loader);
		};
	};

	const handleChangeEditor = (event, editor) => {
		const data = editor.getData();
		handleChange({ target: { data, name: 'body' } });
		setCurrentValue(data);
	};

	const getCategory = (option) => {
		const name = option.name.toLowerCase();
		if (name.includes('seguros')) return 'SEGUROS';
		if (name.includes('lcci')) return 'LCCI';
		if (name.includes('esg')) return 'ESG';
		if (name.includes('mifid')) return 'MIFID';
		return name === '' ? null : 'MIFID';
	};

	const enableDisableCKEditorToolBarButtons = (value) => {
		const recertificationGroup = groupsOptions.find((group) => group.pk === value);
		const category = recertificationGroup ? getCategory(recertificationGroup) : null;

		const editor = editorRef.current;

		if (editor) {
			const toolbarItemsIndex = {
				MIFID: [60, 55, 50, 45, 40, 36],
				LCCI: [61, 56, 51, 46, 41, 37],
				ESG: [62, 57, 52, 47, 42, 38],
				SEGUROS: [63, 58, 53, 48, 43]
			};

			// Indice de inicio de la barra de herramientas de CKEditor, si se añaden o quitan elementos habrá que modificar este número
			const startIndex = 36;

			// Activar todos los elementos de la barra de herramientas por defecto
			for (let i = startIndex; i < editor.ui.view.toolbar.items.length; i++) {
				editor.ui.view.toolbar.items.get(i).isEnabled = true;
			}

			// Si hay una categoría específica, activar solo los elementos correspondientes a esa categoría
			if (category && toolbarItemsIndex[category]) {
				// Desactivar todos los elementos primero
				for (let i = startIndex; i < editor.ui.view.toolbar.items.length; i++) {
					editor.ui.view.toolbar.items.get(i).isEnabled = false;
				}
				// Activar solo los elementos de la categoría específica
				toolbarItemsIndex[category].forEach((index) => {
					if (index < editor.ui.view.toolbar.items.length) {
						editor.ui.view.toolbar.items.get(index).isEnabled = true;
					}
				});
			}
		}
	};

	const handleChange = ({ target: { value, name } }) => {
		if (name === 'level' && value === '') {
			value = null;
		}
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleEmailSelect = ({ target: { value } }) => {
		handleChange({ target: { value, name: 'template' } });
		if (value)
			authenticatedFetcher({ path: `/emails/massive/templates/${value}/` }).then(
				(res) => res.body && setCurrentValue(res.body)
			);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		authenticatedFetcher({
			path: '/emails/massive/',
			method: 'POST',
			body: { ...formData, body: currentValue }
		}).then((res) => {
			if (res.__status || res.error) {
				if (res.error_data && Array.isArray(res.error_data.non_field_errors))
					res.error_data.non_field_errors.map((item) => toastError(item));
				else toastError(res.error);
				return false;
			} else {
				toastSuccess(t('save'));
				window.location.reload();
				return true;
			}
		});
	};

	useEffect(() => {
		enableDisableCKEditorToolBarButtons(parseInt(formData.recertification_group));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.recertification_group, groupsOptions]);

	return (
		<Modal size="sm" toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				Creación Envíos de emails
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="NewPage">
							<div className="card-box card">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="card-body">
												<div className="row">
													<div className="col-md-6 col">
														<Form.Group className="row">
															<Form.Label className="BasicLabel right mb-0 col-3">
																De:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																type="text"
																name="from_mail"
																onChange={handleChange}
															/>
														</Form.Group>
														<Form.Group className="row">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Para:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																type="text"
																name="to_mail"
																onChange={handleChange}
															/>
														</Form.Group>
														<Form.Group className="row">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Asunto:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																type="text"
																name="subject"
																onChange={handleChange}
															/>
														</Form.Group>
													</div>
													<div className="col-md-6 col">
														<Form.Group className="d-flex">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Plantilla:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																as="select"
																name="template"
																value={formData.template}
																onChange={handleEmailSelect}>
																<option value=""></option>
																{mailOptions.map((item) => (
																	<option key={item.pk} value={item.pk}>
																		{item.name}
																	</option>
																))}
															</Form.Control>
														</Form.Group>
														<Form.Group className="d-flex">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Tipo de Mensaje:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																as="select"
																name="template_type"
																value={formData.template_type}
																onChange={handleChange}>
																{templateTypesOptions.map((item) => (
																	<option key={item.pk} value={item.pk}>
																		{item.name}
																	</option>
																))}
															</Form.Control>
														</Form.Group>
														<Form.Group className="d-flex">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Grupo Recertificacion:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																as="select"
																value={formData.recertification_group}
																onChange={handleChange}
																name="recertification_group">
																<option value=""></option>
																{groupsOptions.map((item) => (
																	<option key={item.pk} value={item.pk}>
																		{item.name}
																	</option>
																))}
															</Form.Control>
														</Form.Group>
														<Form.Group className="d-flex">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Nivel:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																as="select"
																name="level"
																value={formData.level}
																onChange={handleChange}>
																<option value=""></option>
																{levelsOptions.map((item) => (
																	<option key={item.pk} value={item.pk}>
																		{item.name}
																	</option>
																))}
															</Form.Control>
														</Form.Group>
														<Form.Group className="d-flex">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Filtros:
															</Form.Label>
															<Form.Control
																className="BasicInput col-9"
																as="select"
																name="filters"
																value={formData.filters}
																onChange={handleChange}>
																<option value=""></option>
																{filterTypesOptions.map((item) => (
																	<option key={item.pk} value={item.pk}>
																		{item.name}
																	</option>
																))}
															</Form.Control>
														</Form.Group>
														<Form.Group className="d-flex">
															<Form.Label className="BasicLabel right mb-0 col-3">
																Incluir Bajas
															</Form.Label>
															<TypeFormInputBoolean
																name="with_drops"
																onChange={handleChange}
																inputClass="BasicLabel mr-2"
																value={formData.with_drops}
																onlyInput
																editable
															/>
														</Form.Group>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<CKEditor
															editor={Editor}
															data={currentValue}
															onReady={handleOnInit}
															onChange={handleChangeEditor}
															name="body"
														/>
													</div>
												</div>
												<div className="w-100 my-3">
													<Button
														type="submit"
														variant="secondary ml-auto d-block"
														onClick={handleSubmit}
														className="submitButton">
														{t('submit')}
													</Button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}

CustomModalCreateMassiveEmail.defaultProps = {
	toggle: () => {}
};
