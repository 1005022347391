import React, {Fragment, useContext, useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AppContext from '../../context';
import moment from 'moment';
import locales from 'moment/min/locales';
import authenticatedFetcher, { deleteUserInfo } from '../../services/fetcher';
import {APP_TITLE, WITH_LANGUAGES} from '../../config';
import AlarmIcon from '../../components/AlarmsIcon';
import GlobalFilters from '../../components/GlobalFilters';
import BasicSelect from '../../components/BasicSelect';
import { readSessionStorage, writeSessionStorage } from '../../services/sessionStorageManagement';

const HeaderUserbox = () => {
	const { config, user } = useContext(AppContext);
	const [langs, setLangs] = useState([]);
	const [currentLang, setCurrentLang] = useState();
	const history = useHistory();
	const { t } = useTranslation(['app', 'header']);

	useEffect(() => {
		if (WITH_LANGUAGES)
			authenticatedFetcher({
				path: '/config/languages/',
				langReq: true
			}).then((data) => {
				if (Array.isArray(data)) {
					const savedLang = readSessionStorage('i18nextLng');
					setLangs(data);
					setCurrentLang(savedLang || data[0].pk);
					moment.locale(savedLang || data[0].pk, locales);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLogout = () => deleteUserInfo(true);

	const handleChange = ({ target: { value } }) => {
		setCurrentLang(value);
		writeSessionStorage('i18nextLng', value);
		history.go(0);
	};

	const handleGoBack = () => history.goBack();

	const hiddenOptions = (process.env.REACT_APP_CUSTOM_HOME
		&& (window.location.pathname === '/' || config.globalParams['app'] === 'plani'));
	const printLogo = (process.env.REACT_APP_CUSTOM_HOME && window.location.pathname === '/');
	const projectLogo = process.env.REACT_APP_IMAGE_BAR;

	return (
		<div className="header-container">
			{!hiddenOptions ? (
				<Fragment>
					<button className="go-back d-flex align-items-center" onClick={handleGoBack}>
						<FontAwesomeIcon icon={faArrowLeft} />
						<span className="d-none d-md-block">{t('header:goBack')}</span>
					</button>
				</Fragment>
			) : (
				<Fragment>
					{printLogo ? (
						<NavLink to="/" title={APP_TITLE} className="app-sidebar-logo">
							<img alt={APP_TITLE} src={projectLogo} style={{ width: '182px' }} />
						</NavLink>
					) : (
						<span></span>
					)}
				</Fragment>
			)}

			<GlobalFilters filters={config.global_filters}>
				{!!WITH_LANGUAGES && (
					<BasicSelect
						options={langs}
						keyField="pk"
						printField="name"
						onChange={handleChange}
						initialValue={currentLang}
						className="small"
						withoutBlankOption
					/>
				)}
			</GlobalFilters>

			<div className="d-flex align-items-center mr-0 mr-md-5">
				<div className="font-weight-bold">
					{user?.userName}
					{!hiddenOptions && (
						<AlarmIcon />
					)}
				</div>
				<div className="wrapper-sidebar-menu logout">
					<div className="wrapper-sidebar-submenu logout" onClick={handleLogout}>
						<i className="fas fa-sign-out-alt"></i>
						<span className="d-none d-md-block">{t('login.closeSession')}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderUserbox;
