import React from 'react';

import { Col, FormGroup, Label, FormFeedback } from 'reactstrap';
import { Types } from '../../../utils/DynamicForm.config';


const TypeFormInputEmpty = (props) => {
	const {
		labelColumn = {
			xs: 6,
			md: 2
		},
		divClassName
	} = props;

	return (
		<FormGroup tag="fieldset" row className={divClassName}>
			<Col {...labelColumn}>
				<Label >
					&nbsp;
				</Label>
			</Col>
		</FormGroup>
	);
};
TypeFormInputEmpty.propTypes = Types.inputType;

TypeFormInputEmpty.defaultProps = {
	type: 'empty'
};

export default TypeFormInputEmpty;
