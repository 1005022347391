import React, { Fragment, useState } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

// import classnames from 'classnames';
import ListBlock from '../../ListBlock';

const TypeTabs = (props) => {
	const { groupedData, isCreating, config } = props;

	const [activeTab, setActiveTab] = useState(0);

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const renderTabsHead = (tabs) => {
		return (
			<Fragment>
				<Nav tabs className="h">
					{tabs &&
						tabs.map((t, i) => {
							if (isCreating && !t.in_creation_form) return null;
							return (
								<NavItem key={'RelatedLists ' + i}>
									<NavLink
										className={`${activeTab === t.title ? 'active' : ''} RelatedLists-navTabs`}
										onClick={() => {
											toggleTab(t.title);
										}}>
										{t.title}
									</NavLink>
								</NavItem>
							);
						})}
				</Nav>
			</Fragment>
		);
	};

	const renderTitle = (title) =>
		title ? (
			<Row>
				<Col md="12">
					<div className="card-header py-3">
						<div className="card-header--title font-size-lg">{title}</div>
					</div>
				</Col>
			</Row>
		) : null;

	return groupedData.map((gd, i) => {
		if (gd.tabs.length === 1) {
			const [t] = gd.tabs;
			return (
				<ListBlock
					key={`RelatedList-${i}`}
					config={t.config}
					params={t.params}
					tab={t}
					relatedID={`${gd.id}-0`}
					id={t.uri}
					className="mt-4"
					customName={t.originConfig.title_fixed}
					withoutPlusBtn={'button_creation' in t.originConfig ? !t.originConfig.button_creation : false}
					withoutButtonsBar={'print_buttons' in t.originConfig ? !t.originConfig.print_buttons : false}
					initialConfig={t.originConfig}
					withContext
				/>
			);
		}
		return (
			<Card className="card-box mt-4 mb-4" key={`RelatedCard-${i}`}>
				{renderTitle(gd.title)}
				{renderTabsHead(gd.tabs)}
				<TabContent activeTab={activeTab}>
					{gd.tabs.map((t, j) => {
						if (isCreating && !t.in_creation_form) return null;
						return (
							<TabPane tabId={t.title} key={`RelatedTab-${j}`}>
								<Row>
									<Col md="12">
										<div className="card-body p-2">
											{activeTab === t.title ? (
												<ListBlock
													config={t.config}
													params={t.params}
													tab={t}
													id={t.uri}
													relatedID={`${gd.id}-${j}`}
													customName={t.originConfig.title_fixed}
													withoutPlusBtn={
														'button_creation' in t.originConfig
															? !t.originConfig.button_creation
															: false
													}
													withoutButtonsBar={
														'print_buttons' in t.originConfig
															? !t.originConfig.print_buttons
															: false
													}
													initialConfig={t.originConfig}
													withoutMargin
													withContext
												/>
											) : null}
										</div>
									</Col>
								</Row>
							</TabPane>
						);
					})}
				</TabContent>
			</Card>
		);
	});
};

export default TypeTabs;
